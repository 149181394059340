import { BusinessSystemPerson } from "../business-system-person.model";

export class ConnectFleetWebUserResponse {
    
    constructor(jsonObject: any = null) {
        if(jsonObject == null){
          return;
        }
    
        this.businessSystemPerson = jsonObject.businessSystemPerson ? new BusinessSystemPerson(jsonObject.businessSystemPerson): null;        
      }

    businessSystemPerson: BusinessSystemPerson;
}
