import { FleetWebOrganizationDetails } from "./fleetweb-organization-details.model";

export class CreateAuthwayOrganizationResponse {

    constructor(jsonObject: any = null){
        if(jsonObject == null){
            return;
        }
  
        this.fleetWebOrganizationDetails = jsonObject.fleetWebOrganizationDetails ? new FleetWebOrganizationDetails(jsonObject.fleetWebOrganizationDetails) : null;
    }
  
    fleetWebOrganizationDetails: FleetWebOrganizationDetails;
}