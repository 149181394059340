import { IValidation } from './validation.interface';

export class CarSpecificationValidation implements IValidation{
    
    NewFlag: Array<string>;
    Make: Array<string>;
    Mileage: Array<string>;
    ProductionYear: Array<string>;
    License: Array<string>;
    MakeId: Array<string>;
    ModelId: Array<string>;
    Model: Array<string>;
    OfferNumber: Array<string>;
    OfferDocument: Array<string>;
    OrderNumber: Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.NewFlag = jsonObj['Car.NewFlag'];
            this.ProductionYear = jsonObj['Car.ProductionYear'];
            this.Make = jsonObj['Car.Make'];
            this.Mileage = jsonObj['Car.Mileage'];
            this.License = jsonObj['Car.License'];
            this.MakeId = jsonObj['Car.MakeId'];
            this.ModelId = jsonObj['Car.ModelId'];
            this.Model = jsonObj['Car.Model'];
            this.OfferNumber = jsonObj['Car.OfferNumber'];
            this.OfferDocument = jsonObj['Car.OfferDocument'];
            this.OrderNumber = jsonObj['OrderNumber'];
        }
    }    

    get isValid(){        
        return this.errors.length == 0;
    }

    get errors(){
        var errors = new Array<string>();
        
        if(this.NewFlag)
        {
            this.NewFlag.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.Make)
        {
            this.Make.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.ProductionYear)
        {
            this.ProductionYear.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.Mileage)
        {
            this.Mileage.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.License)
        {
            this.License.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.MakeId)
        {
            this.MakeId.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.ModelId)
        {
            this.ModelId.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.Model)
        {
            this.Model.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.OfferNumber)
        {
            this.OfferNumber.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.OfferDocument)
        {
            this.OfferDocument.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.OrderNumber)
        {
            this.OrderNumber.forEach(mess => {
                errors.push(mess);
            });
        }

        return errors;
    }
}
