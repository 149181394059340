<form *ngIf="formGroup" [formGroup]="formGroup" class="d-flex justify-content-start">
  <div class="c-input w-100">
    <app-frame-agreement-search class="w-100"
      formControlName="frameAgreement"
      [includeDisabled]="includeDisabled">
    </app-frame-agreement-search>
  </div>
  <div class="switch ms-2 mt-1">
    <input type="checkbox" class="switch" [(id)]="uniqueId" formControlName="includeDisabled">
    <label [(for)]="uniqueId" class="text-nowrap">Visa inaktiva</label>
  </div>  
</form>
