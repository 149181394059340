import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl,  FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-editor',
    templateUrl: './input-editor.component.html',
    styleUrls: ['./input-editor.component.scss']
})
export class InputEditorComponent implements OnInit {

    @Input() labelText: string;
    @Input() inputText: string;
    @Input() requiredMessage: string;
    @Output() newValue: EventEmitter<string> = new EventEmitter();

    formGroup: FormGroup;
    inputFormControl: FormControl<string>;

    ngOnInit() {        
        this.initForm();   
      }

    get isEditing(){
        return !this.inputFormControl.disabled;
    }

    get isError(){
        return this.inputFormControl.dirty && this.inputFormControl.errors;
    }

    setValue(inputText: string) {
        this.inputText = inputText;
        this.inputFormControl.setValue(inputText);
    }
        
    private initForm() {
        this.inputFormControl = new FormControl<string>(this.inputText, [Validators.required]);
        this.inputFormControl.disable();
            
        this.formGroup = new FormGroup(
          {
            input: this.inputFormControl
        });
    }
    
    onEdit(){
        this.inputFormControl.enable();
    }

    onCancel(){
        this.inputFormControl.setValue(this.inputText);        
        this.inputFormControl.disable();
    }

    onSave(){
        if(!this.formGroup.valid)
        {
            this.inputFormControl.markAsDirty();
            return;
        }

        var newValue = this.inputFormControl.value;
        this.newValue.emit(newValue);
        
        this.inputFormControl.reset();
        this.inputFormControl.disable();           
        this.inputFormControl.setValue(newValue);     
    }
}