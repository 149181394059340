import { FrameAgreement } from "../frame-agreement.model";
import { FleetWebOrganizationInfo } from "./fleetweb-organization-info.model";

export class FleetWebFrameAgreementInfo extends FrameAgreement {

    constructor(jsonObject: any) {
        super(jsonObject);

        if(jsonObject == null){
            return;
        }
        
        this.organizations = jsonObject.organizations ? jsonObject.organizations.map(o => new FleetWebOrganizationInfo(o)): new Array<FleetWebOrganizationInfo>();
    }

    organizations : Array<FleetWebOrganizationInfo> = new Array<FleetWebOrganizationInfo>();    
}