export class UserCostCenterLimitationInfo {
    constructor(jsonObject: any = null) {   
    
        if(jsonObject == null){
            return;
        }
        
        this.id = jsonObject.id;
        this.text = jsonObject.text;
    }
      
    id: number;
    text: string;
}
  