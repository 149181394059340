import { UserCostCenterLimitationInfo } from "./user-costcenter-limitation-info.model";

export class UserOrganizationInfo {
    constructor(jsonObject: any = null) {   
    
        if(jsonObject == null){
            return;
        }
        
        this.customerId = jsonObject.customerId;
        this.userCostCenterLimitationInfo = jsonObject.userCostCenterLimitationInfo ? 
            jsonObject.userCostCenterLimitationInfo.map(info => new  UserCostCenterLimitationInfo(info)) : 
            new Array<UserCostCenterLimitationInfo>;        
        this.inSufficientCostCenterPrivileges = jsonObject.inSufficientCostCenterPrivileges;
    }
      
    customerId: number;
    userCostCenterLimitationInfo: Array<UserCostCenterLimitationInfo> = new Array<UserCostCenterLimitationInfo>();
    inSufficientCostCenterPrivileges: boolean;
  }
  