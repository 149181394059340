<div id="modal-5" class="c-modal" tabindex="-1" role="dialog">
  <div class="c-modal__overlay"></div>
  <div class="c-modal__wrapper">
    <div class="c-modal__header">
      <button type="button" class="c-modal__close-btn close-5-modal" aria-label="Close" (click)="onAbort()"></button>
    </div>
    <div class="c-modal__content mb-4">
      <header>
        <h3 class="text-start">Skapa ny användare</h3>
      </header>
      <div class="alert alert-info">
        <p>
          Först kommer användaren skapas upp i Authway under den organisation som valts.
          Sedan kommer användaren kopplas till vald person i affärssystemet.
          Slutligen kommer ett Välkomstbrev med instruktioner för inloggning att mailas ut till användaren.
        </p>
        <p>
          Glöm inte att redigera användaren och lägga till behörigheter, i de fall att inte organisationen i Authway konfigurerats att tilldela grupper per automatik.
        </p>
        
      </div>
        <ng-template [ngIf]="!isValidating" [ngIfElse]="validating">
          <div *ngIf="businessSystemPerson">
            <div class="mb-4 c-card bg-grey">
              <div>
                <span><b>Förnamn: </b>{{ businessSystemPerson.firstName }}</span>
              </div>
              <div>
                <span><b>Efternamn: </b>{{ businessSystemPerson.lastName }}</span>
              </div>
              <div>
                <span><b>Personnummer: </b>{{ businessSystemPerson.socialSecurityNumber }} </span>
              </div>
              <div>
                <span><b>Roller: </b>{{ businessSystemPerson.roles }} </span>
              </div>
              <div>
                <span><b>Kundnummer: </b>{{ businessSystemPerson.customerNumber }} </span>
              </div>
              <div>
                <span><b>Mobilnummer: </b>{{ businessSystemPerson.mobilePhone }} </span>
              </div>
              <div>
                <span><b>E-postadress: </b>{{ businessSystemPerson.email }} </span>
              </div>
              <div>
                <span><b>Anställningsnummer: </b>{{ businessSystemPerson.employeeId }} </span>
              </div>
            </div>         
          </div>
          <div *ngIf="validationErrorMessage" class="alert alert-danger">
            <p>{{validationErrorMessage}}</p>
          </div>
        </ng-template>
        <ng-template #validating>
          <div class="spinner-wrapper mb-4">
            <app-spinner [style]="'section'"></app-spinner>
          </div>
        </ng-template>
      <form class="mt-2" [formGroup]="formGroup" *ngIf="businessSystemPerson">
        <div class="o-fieldset__row mt-4 mb-2">
          <label for="frameAgreementInput">Ramavtal*</label>
          <app-frame-agreement-search-advanced id="frameAgreementInput" class="w-100" formControlName="frameAgreement"></app-frame-agreement-search-advanced>
          <small *ngIf="frameAgreementInvalid" class="is-error">
            Ramavtal måste anges.
          </small>
        </div>
        <div class="o-fieldset__row mt-4 mb-2">
          <label for="tenantInput">Organisation*</label>
          <select id="tenantInput" formControlName="tenant" class="c-input__input js-multi-accordion__select" [class.is-error]="organizationsInvalid">
            <option *ngIf="tenants.length > 1 && !selectedTenant" [value]="null">- Välj organisation -</option>
            <option *ngFor="let tenant of tenants" [value]="tenant.tenantId">{{tenant.name}} ({{tenant.organizationNumber}})</option>
          </select>
          <small *ngIf="organizationsMissing" class="is-error">
            Det finns inga organisationer i Authway kopplade mot aktuellt ramavtal. Administrera organisationer först för att sedan skapa användare.
          </small>
          <small *ngIf="organizationRequired" class="is-error">
            Organisation måste anges.
          </small>
        </div>
      </form>
      <div *ngIf="createErrorMessage" class="mt-4">
        <span class="is-error">{{createErrorMessage}}</span>
      </div>
      <div *ngIf="connectErrorMessage" class="mt-4">
        <p>
          <span class="is-error">{{connectErrorMessage}}</span>
        </p>
        <p>
          <span class="is-error">Användaren {{ businessSystemPerson.email }} är skapad. Välj "Avbryt" för att göra ett nytt försök att koppla användaren.</span>
        </p>
      </div>
    </div>
    <div class="c-modal__footer d-flex justify-content-between">
      <button type="button" class="c-btn c-btn--secondary" (click)="onAbort()">
        <span class="c-btn__text">Avbryt</span>
      </button>
      <button type="button" class="c-btn" aria-label="Close" (click)="onCreate()" [disabled]="createDisabled">
        <div *ngIf="isCreating || isConnecting" class="button-spinner-wrapper spinner-wrapper me-2">
          <app-spinner [style]="'small'"></app-spinner>
        </div>
        <span *ngIf="isCreating" class="c-btn__text">Skapar användare...</span>
        <span *ngIf="isConnecting" class="c-btn__text">Kopplar användare...</span>
        <span *ngIf="!isCreating && !isConnecting" class="c-btn__text">Skapa</span>
      </button>
    </div>
  </div>
</div>
