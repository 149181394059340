import { Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerPart } from "@domain/models/customer-part.model";
import { CustomerService } from "@domain/services/customer.service";
import { SearchCustomerQuery } from "@domain/models/filters/search-customer-query.model";
import { QueryResult } from "@domain/models/query-result.model";
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";

@Component({
  standalone: true,
  selector: "app-customer-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CustomerSearchComponent
    }
  ]
})
export class CustomerSearchComponent  extends TypeAheadSearchComponent<CustomerPart> {
  
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 15;

  @Input() excludePartIds = new Array<number>();

  constructor(private customerService: CustomerService,
    parentFormGroup: FormGroupDirective) {
    super(parentFormGroup);    
  }

  public override get placeholderText(): string {
    return "Sök företag/organisation";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: CustomerPart): string {
    if (!match || match == null) {
      return "";
    }
    
    return  `${match.name ? match.name.trimEnd() : ""} ${match.organizationNumber ? match.organizationNumber: ""}`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade företag/organisationer.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på företagets/organisationens namn eller nummer med minst ${minSearchTermLength} bokstäver.`;
  }

  protected override searchAction(term: string): Observable<QueryResult<CustomerPart>> {
    const searchQuery = new SearchCustomerQuery();
    searchQuery.searchText = term.toString();
    searchQuery.pageSize = this._maxNumberOfResults;
    searchQuery.excludePartIds = this.excludePartIds;

    return this.customerService.searchCustomers(searchQuery);
  }
  
  protected override isInstanceOfType(value: CustomerPart): boolean {
    return value instanceof CustomerPart;
  }

  protected override strikeThroughText(_: CustomerPart): boolean {
    return false;
  }
}
