import { Component, Input } from '@angular/core';
import { ContentType } from '@domain/models/content-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlobHelper } from '@shared/helpers/blob.helper';
import { LoadingHelper } from '@shared/helpers/loading.helper';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  @Input() title: string;
  @Input() file: File;
  @Input() acceptedContentTypes: ContentType[];
  @Input() maxFileSizeInMb: number;
  @Input() replaceFile: string;

  private _loadingHelper = new LoadingHelper();
  errorMessage: string;

  constructor(public modal: NgbActiveModal) { 
  }

  get accept() {
    return this.acceptedContentTypes.map(at => at.mimeType);
  }

  get acceptedExtensionsText (){
    var acceptedText = "";          
    acceptedText += this.acceptedContentTypes[0].fileExtension;

    if(this.acceptedContentTypes.length > 1) {      
      const numberOfTypes = this.acceptedContentTypes.length;
      for(var i = 1; i < numberOfTypes-1; i++)
      {
        acceptedText += `, ${this.acceptedContentTypes[i].fileExtension}`;
      }
      acceptedText += ` och ${this.acceptedContentTypes[numberOfTypes-1].fileExtension}`;
    }
    return acceptedText;
  }

  get suggestedContentType(){
    if(this.acceptedContentTypes.length == 1) {
      return this.acceptedContentTypes[0].mimeType;
    }
    
    return null;
  }

  private convertMBToByte(numberOfMb: number) {
    const numberOfBytesInOneMegaByte = 1048576;
    return numberOfMb * numberOfBytesInOneMegaByte;
  } 

  get loading (){
    return this._loadingHelper.isLoading;
  }

  uploadFile(event) {
    this._loadingHelper.startLoading();

    const tempFile = event[0] as File;
    
    if(!this.acceptedContentTypes || this.acceptedContentTypes.length == 0) {
      this.errorMessage = "Inga filtyper stöds.";    
    }
    else if (!this.acceptedContentTypes.find(a => a.mimeType === tempFile.type)) {
      
     
      this.errorMessage = `Filtypen stöds inte. Endast ${this.acceptedExtensionsText} kan användas.`;
      
    }
    else if (tempFile.size === 0) {
      this.errorMessage = "Filen är tom. Försök igen med en annan fil.";
    }
    else if (tempFile.size > this.convertMBToByte(this.maxFileSizeInMb)){
      this.errorMessage = `Filens storlek överskrider ${this.maxFileSizeInMb} mb. Försök igen med en annan fil.`;
    }
    else if (this.isInvalidName(tempFile.name)) {
      this.errorMessage = "Filnamnet är otillåtet."
    }
    else {
        this.file = tempFile;
    }
    
    this._loadingHelper.stopLoading();
  }

  openFile() {
    BlobHelper.openFile(this.file, this.file.name);
  }

  removeFile() {
    this.file = null;
  }

  private isInvalidName(name: string): boolean {
    var pattern = new RegExp('^[0-9a-zA-ZåÅäÄöÖ. ()_\-]+$');
    var result = pattern.test(name);
    return !result;
  }
}
