export enum Severity {
    Error,
    Warning,
    Information
}

export class UserValidationProblem{
    constructor(jsonObject: any) {
        this.message = jsonObject.message;
        this.severity = jsonObject.severity;        
        this.code = jsonObject.code;
      }
    
      message: string;
      severity: Severity;
      code: string;
}