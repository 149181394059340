import { Injectable } from "@angular/core";
import { ApplicationError } from "@core/models/application-error.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonInfoMessageComponent } from "@shared/components/modals/common-info-message/common-info-message.component";
import { ConfirmDialogComponent } from "@shared/components/modals/confirm-dialog/confirm-dialog.component";

@Injectable()
export class DialogService {

    constructor(private _modalService : NgbModal) {

    }

    showError(error: any, title: string, showContactFleet = false) {    

        var message = error instanceof ApplicationError ?
            error.fullMessage(showContactFleet) : 
            ApplicationError.getMessage(error);
                
        return this.showErrorMessage(title, message);
    }

    showErrorMessage(title: string, message: string){
        return this.showMessage(title, message, true);
    }

    showInfoMessage(title: string, message: string){
        return this.showMessage(title, message);
    }

    showConfirm(title: string, message: string, okButtonText: string, cancelButtonText: string){
        var modalRef =  this._modalService.open(
            ConfirmDialogComponent,
          { backdrop: "static", size: "sm", animation: false, }
        );

        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.buttonText = okButtonText;
        modalRef.componentInstance.cancelButtonText = cancelButtonText;

        return modalRef;
    }

    private showMessage(title: string, message: string, isError = false) {
        const modalRef = this._modalService.open(
            CommonInfoMessageComponent,
            { backdrop: "static", size: "sm", animation: false }
        );
      
        modalRef.componentInstance.error = isError;      
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;

        return modalRef;
    }  
}
