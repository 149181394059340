import { OrganizationTargetGroup } from "@core/models/organization-target-group.enum";
export class WriteNewsArticleRequest {
  id: number;
  title: string;
  content: string;
  startDateString: string;
  endDateString?: string;
  frontPage: boolean;
  important: boolean;
  showToCustomerIds: number[];
  active: boolean;
  hasBeenPublished: boolean;
  roles: OrganizationTargetGroup[];
}

