import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/services/base-api.service';
import { Observable, map } from 'rxjs';
import { MileageReportModel } from '../models/mileage-report.model';
import { DatePipe } from '@angular/common';
import { MileageReportResponse } from '@domain/models/mileage-report-response.model';
import { AvailableMonthReport } from '@domain/models/available-month-report.model';
import { MileageOverviewItem } from '@domain/models/mileage-overview-item';

@Injectable({
    providedIn: 'root'
})
export class MileageReportService {

    constructor(private _apiService: BaseApiService,
        public _datePipe: DatePipe) { }

    getMileageReport(license: string, year: number, month: number): Observable<MileageReportModel> {
        return this._apiService.get(`MileageReport/${license}?year=${year}&month=${month}`).pipe(
            map(data => {
                return new MileageReportModel(data);
            })
        );
    }

    createMileageReport(reportModel: MileageReportModel): Observable<MileageReportResponse> {
        return this._apiService.post(`MileageReport`, reportModel).pipe(
            map(data => {
                return new MileageReportResponse(data);
            })
        );
    }

    updateMileageReport(reportModel: MileageReportModel): Observable<MileageReportResponse> {
        return this._apiService.put(`MileageReport`, reportModel).pipe(
            map(data => {
                return new MileageReportResponse(data);
            })
        );
    }

    getAvailableMonths(license: string): Observable<Array<AvailableMonthReport>> {
        return this._apiService.get(`MileageReport/AvailableMonths/${license}`).pipe(
            map(data =>{
            
                var availableMonths = data
                    .map(x => new AvailableMonthReport(x));

                return availableMonths;
            })
        );
    }

    getMileageStart(license: string, year: number, month: number): Observable<number> {
        return this._apiService.get(`MileageReport/StartMileage/${license}?year=${year}&month=${month}`);
    }

    getReportingOverview(license: string) : Observable<Array<MileageOverviewItem>> {
        return this._apiService.get(`MileageReport/Overview/${license}`).pipe(
            map(data => {
                return data.map(d => { return new MileageOverviewItem(d); });
            })
        );
    }
    
    getReportingOverviewExcel(license: string) : Observable<Blob> {
        return this._apiService.download(`MileageReport/OverviewReportExcel/${license}`, license);
    }
}
