import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, map } from 'rxjs';

// TODO News behövs inte...
import { WriteNewsArticleRequest, GetNewsArticleResponse } from '@domain/models/news';

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  constructor(private _apiService: BaseApiService) { }

  getSingleNews(id: number): Observable<GetNewsArticleResponse> {
    return this._apiService.get("News/" + id).pipe(
      map(data => new GetNewsArticleResponse(data)));
  }

  getNews(): Observable<Array<GetNewsArticleResponse>> {
    return this._apiService.get("News").pipe(
      map(data => data.map(a => new GetNewsArticleResponse(a))));
  }

  getFrontPageNews(): Observable<Array<GetNewsArticleResponse>> {
    return this._apiService.get("News/FrontPageNews").pipe(
      map(data => data.map(a => new GetNewsArticleResponse(a))));
  }

  updateNewsArticle(request: WriteNewsArticleRequest): Observable<void> {
    return this._apiService.put("News", request);
  }

  createNewsArticle(request: WriteNewsArticleRequest): Observable<any> {
    return this._apiService.post("News", request);
  }

  deleteNewsArticle(id: number): Observable<void> {
    return this._apiService.delete("News/" + id);
  }
}
