import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@core/services/dialog.service';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { AuthwayOrganizationInfo } from '@domain/models/organization/authway-organization-info.model';
import { CreateFleetWebUserRequest } from '@domain/models/user/create-fleetweb-user-request.model';
import { FleetWebUserInfo } from '@domain/models/user/fleetweb-user-info.model';
import { FleetWebOrganizationService } from '@domain/services/fleetweb-organization.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription } from 'rxjs';
import { ConnectFleetWebUserRequest } from '@domain/models/user/connect-fleetweb-user-request.model';
import { UserService } from '@domain/services/user.service';
import { ApplicationError } from '@core/models/application-error.model';
import { ValidationErrorResponse } from '@core/models/validation-error-response.model';
import { BusinessSystemPerson } from '@domain/models/business-system-person.model';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnDestroy, OnInit {

  @Input()
  userInfo: FleetWebUserInfo = null;
  
  private _businessSystemPerson: BusinessSystemPerson;
  private _componentSubscriptions = new Array<Subscription>();

  formGroup: FormGroup;
  private _frameAgreementFormControl: FormControl<FrameAgreement>;
  private _tenantFormControl: FormControl<string>;
  private _tenants = new Array<AuthwayOrganizationInfo>();

  private _validationLoadingHelper = new LoadingHelper();
  private _tenantsLoadingHelper = new LoadingHelper();
  private _createLoadingHelper = new LoadingHelper();
  private _connectLoadingHelper = new LoadingHelper();

  _createErrorMessage = null;
  _connectErrorMessage = null;
  _validationErrorMessage = null;

  private userId: string = null;

  constructor(
    private _fleetWebOrganizationService: FleetWebOrganizationService,
    private _dialogService: DialogService,
    private _userService: UserService,
    public modal: NgbActiveModal) { }


  ngOnInit(): void {
    this.initForm();
    this.validateConnection();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  get validationErrorMessage():string{
    return this._validationErrorMessage;
  }
    
  get createErrorMessage():string{
    return this._createErrorMessage;
  }

  get connectErrorMessage():string{
    return this._connectErrorMessage;
  }

  get frameAgreementInvalid(): boolean {
    return this._frameAgreementFormControl.errors && this._frameAgreementFormControl.errors.required && this._frameAgreementFormControl.dirty;
  }

  get organizationsInvalid(): boolean {
    return this.organizationsMissing || this.organizationRequired;
  }

  get organizationsMissing(): boolean {
    return this._frameAgreementFormControl.value != null && !this._tenantsLoadingHelper.isLoading && this._tenants.length == 0;
  }

  get organizationRequired(): boolean {
    return this._tenantFormControl.errors && this._tenantFormControl.errors.required && this._tenantFormControl.dirty;
  }

  get isValidating(): boolean{
    return this._validationLoadingHelper.isLoading;
  }

  get isTenantsLoading(): boolean {
    return this._tenantsLoadingHelper.isLoading;
  }

  get isCreating(): boolean {
    return this._createLoadingHelper.isLoading;
  }

  get isConnecting(): boolean {
    return this._connectLoadingHelper.isLoading;
  }

  get isCreated() {
    return this.userId != null;
  }

  get businessSystemPerson () {
    return this._businessSystemPerson;
  }

  get tenants(){
    return this._tenants;
  }

  get selectedTenant() {
    return this._tenantFormControl.value;
  }

  get createDisabled(){
    return this.isValidating || this.validationErrorMessage || this.isCreating || this.isCreated; 
  }

  initForm() {
    this._frameAgreementFormControl = new FormControl<FrameAgreement>(this.userInfo.frameAgreement, { validators: [Validators.required] });
    this._tenantFormControl = new FormControl<string>(null, { validators: [Validators.required] });

    if(this.userInfo.frameAgreement){  
      this._frameAgreementFormControl.disable();
      this.getOrganizations();
    }

    this._componentSubscriptions.push(this._frameAgreementFormControl.valueChanges.subscribe(value => {      
      if (value) {
        this.getOrganizations();
      }
      else {
        this._tenantFormControl.setValue(null);
      }
    }));

    this.formGroup = new FormGroup(
      {
        frameAgreement: this._frameAgreementFormControl,
        tenant: this._tenantFormControl
      });
  }

  private validateConnection() {
    this._validationLoadingHelper.startLoading();
    var customerId = this.userInfo.customerId;

    this._userService.validateConnect(customerId).subscribe({
      next: (response) => {
        if (response instanceof ValidationErrorResponse) {
          this._validationErrorMessage = response.error.detail;
        }
        else {
          this._businessSystemPerson = response;
        }
      },
      error: (error) => {
        this._dialogService.showError(error, "Kontrollera koppling till person i affärssystemet");
        this._validationLoadingHelper.stopLoading();
      },
      complete: () => {
        this._validationLoadingHelper.stopLoading();
      }
    });
  }

  getOrganizations() {
    this._tenantsLoadingHelper.startLoading();

    this._fleetWebOrganizationService.getAuthwayOrganizationsByFrameAgreementId(this._frameAgreementFormControl.value.id).subscribe({
      next: (result) => {
        this._tenants = result;

        if (this._tenants.length === 0) {
          this._tenantFormControl.setValue(null);          
        }
        if (this._tenants.length === 1) {          
          this._tenantFormControl.setValue(this._tenants[0].tenantId);          
        }
        if (this._tenants.length > 1) {
          this._tenantFormControl.setValue(null);
        }
      },
      error: (error) => {
        this._tenantsLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Hämta ramavtalets organisationer");
      },
      complete: () => {
        this._tenantsLoadingHelper.stopLoading();
      }
    });
  }

  onCreate() {
    if (this.isCreating || this.isCreated) {
      return;
    }

    if (!this.formGroup.valid) {
      this._frameAgreementFormControl.markAsDirty();
      this._tenantFormControl.markAsDirty();
      return;
    }

    this._createErrorMessage = null;
    this._createLoadingHelper.startLoading();

    var request = new CreateFleetWebUserRequest();
    request.firstName = this._businessSystemPerson.firstName;
    request.lastName = this._businessSystemPerson.lastName;
    request.tenantId = this._tenantFormControl.value;
    request.userName = this._businessSystemPerson.email;

    this._userService.create(request).subscribe({
      next: (userId: string) => {
        this._createLoadingHelper.stopLoading();
        this.userId = userId;
        if (this.userInfo.customerId != null) {
          this.connectUser(userId);
        }
        else {
          this.modal.close(userId);
        }
      },
      error: (error) => {
        this._createLoadingHelper.stopLoading();
        this._createErrorMessage = ApplicationError.getMessage(error);
      }
    });
  }

  connectUser(userId: string) {
    this._connectErrorMessage = null;
    this._connectLoadingHelper.startLoading();

    var request = new ConnectFleetWebUserRequest();
    request.userId = userId;
    request.tenantId = this._tenantFormControl.value;
    request.customerId = this.userInfo.customerId;
    
    this._userService.connectUser(request).subscribe({
      next: (response: any) => {
        if (response instanceof ValidationErrorResponse) {
          this._connectErrorMessage = response.error.title + " " +  response.error.detail;
        }
        else {
          this.modal.close(userId);
        }
      },
      error: (error) => {
        this._connectLoadingHelper.stopLoading();
        this._connectErrorMessage = ApplicationError.getMessage(error);
      },
      complete: () => {
        this._connectLoadingHelper.stopLoading();
      }
    });
  }

  onAbort() {
    this.modal.close(this.userId);
  }
}
