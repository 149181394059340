import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { CarService } from "@domain/services/car.service";
import { CarMatch } from "@domain/models/car-match.model";
import { CarAndDriverQuery } from "@domain/models/filters/car-and-driver-query-model";
import { DateHelper } from "@shared/helpers/date.helper";
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";
import { QueryResult } from "@domain/models/query-result.model";

@Component({
  standalone: true,
  selector: "app-car-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CarSearchComponent
    }
  ]
})
export class CarSearchComponent extends TypeAheadSearchComponent<CarMatch> {
  @Input() marketProductIds: number[];
  @Input() reportingFilter: number;
  @Input() activeDuringYear: number;
  @Input() activeDuringMonth: number;
  @Input() activeDuringOneYearBackInTime: boolean;
  @Input() excludeAdminAccounts: boolean;
  @Input() showSearchInstruction: boolean = false;

  private readonly _minSearchTermLength = 2;
  private readonly _maxNumberOfResults = 10;
  
  constructor(private carService: CarService) {
    super();
  }
  
  public override get placeholderText(): string {
    return "Sök registreringsnummer, förnamn eller efternamn";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: CarMatch) {
      var formattedText = "";

      formattedText += match.firstName ? `${match.firstName} ` : "";
      formattedText += match.lastName ? `${match.lastName} ` : "";
      formattedText += (match.firstName === null && match.lastName === null) ? "" : "- ";
      formattedText += match.make ? `${match.make} ` : "";
      formattedText += match.model ? `${match.model} ` : "";
      formattedText += match.license ? `(${match.license}) ` : "";

      return formattedText;
  }

  protected override formattedResultText(resultCount: number, totalCount: number) {
    return `Visar ${resultCount} av totalt ${totalCount} hittade bilar.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return this.showSearchInstruction ? 
      "Du kan söka på registreringsnummer, förnamn eller efternamn med minst "+ minSearchTermLength +" bokstäver." :
      "";
  }
  
  protected override searchAction(searchTerm: string): Observable<QueryResult<CarMatch>> {
    var query = new CarAndDriverQuery();
    query.pageSize = this._maxNumberOfResults;
    query.licenceOrDriverName = searchTerm.toString();

        if (this.activeDuringOneYearBackInTime) {
          query.activeDuringOneYearBackInTime = true;
        }
        else if (this.activeDuringYear == null || this.activeDuringMonth == null) {
          query.activeAtDateString = DateHelper.nowInSwedishDateFormat;
        }
        else {
          query.activeDuringYear = this.activeDuringYear;
          query.activeDuringMonth = this.activeDuringMonth;
        }

    if (this.excludeAdminAccounts) {
      query.excludeAdminAccounts = true;
    }

    if (this.reportingFilter != null && this.reportingFilter != undefined) {
      query.reportingFilter = this.reportingFilter;
    }

    if (this.marketProductIds != null && this.marketProductIds.length > 0) {
      query.marketProductIds = this.marketProductIds;
    }

    return this.carService.searchByFleetManager(query);
  };
  
  protected override isInstanceOfType(value: CarMatch): boolean {
    return value instanceof CarMatch;
  }

  protected override strikeThroughText(_: CarMatch): boolean {
    return false;
  }  
}
