import { AccountingValidation } from './accounting-validation.model';
import { CarSpecificationValidation } from './car-specification-validation.model';
import { DealerValidation } from './dealer-validation.model';
import { DriverValidation } from './driver-validation.model';
import { FuelCardsValidation } from './fuel-cards-validation.model';
import { LeasingParametersValidation } from './leasing-parameters-validation.model';
import { LesseeValidation } from './lessee-validation.model';
import { MessageValidation } from './message-validation.model';
import { SalaryDeductionValidation } from './salary-deduction-validation.model';
import { IValidation } from './validation.interface';
import { AddonsValidation } from "@domain/models/addons-validation.model";

export class SuborderValidation implements IValidation {

  Message: string;

  LesseeValidation: LesseeValidation = new LesseeValidation();
  DealerValidation: DealerValidation = new DealerValidation();
  CarSpecificationValidation: CarSpecificationValidation = new CarSpecificationValidation();
  LeasingParametersValidation: LeasingParametersValidation = new LeasingParametersValidation();
  DriverValidation: DriverValidation = new DriverValidation();
  AccountingValidation: AccountingValidation = new AccountingValidation();
  SalaryDeductionValidation: SalaryDeductionValidation = new SalaryDeductionValidation();
  FuelCardsValidation: FuelCardsValidation = new FuelCardsValidation();
  AddonsValidation: AddonsValidation = new AddonsValidation();
  MessageValidation: MessageValidation = new MessageValidation();
  SignTimeValidation: Array<string>;

  constructor(jsonObj: any = null) {
    if (jsonObj != null) {
      

      this.Message = jsonObj.title;

      this.LesseeValidation = new LesseeValidation(jsonObj.errors);
      this.DealerValidation = new DealerValidation(jsonObj.errors);
      this.CarSpecificationValidation = new CarSpecificationValidation(jsonObj.errors);
      this.LeasingParametersValidation = new LeasingParametersValidation(jsonObj.errors);
      this.DriverValidation = new DriverValidation(jsonObj.errors);
      this.AccountingValidation = new AccountingValidation(jsonObj.errors);
      this.SalaryDeductionValidation = new SalaryDeductionValidation(jsonObj.errors);
      this.FuelCardsValidation = new FuelCardsValidation(jsonObj.errors);
      this.AddonsValidation = new AddonsValidation(jsonObj.errors);
      this.MessageValidation = new MessageValidation(jsonObj.errors);

      this.SignTimeValidation = jsonObj.errors == null || jsonObj.errors.SignTimeValidation == null ?
        new Array<string>() :
        jsonObj.errors.SignTimeValidation;
    }

    if (this.SignTimeValidation == null) {
      this.SignTimeValidation = new Array<string>();
    }
  }

  get isValid() {
    return this.LesseeValidation.isValid &&
      this.DealerValidation.isValid &&
      this.CarSpecificationValidation.isValid &&
      this.LeasingParametersValidation.isValid &&
      this.DriverValidation.isValid &&
      this.AccountingValidation.isValid &&
      this.SalaryDeductionValidation.isValid &&
      this.FuelCardsValidation.isValid &&
      this.AddonsValidation.isValid &&
      this.MessageValidation.isValid;
  }

  get isSignTimeValid() {
    return this.SignTimeValidation.length === 0;
  }

  get errors() {
    var errors = new Array<string>();

    this.LesseeValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.DealerValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.CarSpecificationValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.LeasingParametersValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.DriverValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.AccountingValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.SalaryDeductionValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.FuelCardsValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.AddonsValidation.errors.forEach(err => {
      errors.push(err);
    });
    this.MessageValidation.errors.forEach(err => {
      errors.push(err);
    });

    return errors;
  }

  get signTimeErrorMessage() {
    return this.isSignTimeValid ? "" : this.SignTimeValidation[0];
  }

  getErrorMessage(message: string): string {
    if (this.isValid) {
      return "";
    }

    let errorMessage = "";

    this.errors.forEach(error => {
      if (errorMessage === "") {
        errorMessage = "- " + error;
      } else {
        errorMessage += `\r\n- ${error}`;
      }
    });

    return message + "\r\n" + "Följande fel behöver åtgärdas för att kunna gå vidare:" + "\r\n" + errorMessage;
  }
}
