import { SearchFleetWebUserQuery } from "./search-fleetweb-user-query.model";

export enum ExportType {
    Basic = 0,
    Customers = 1,
    CostCenters = 2
}

export class ExportFleetWebUsersQuery extends SearchFleetWebUserQuery{
    exportType: ExportType = ExportType.Basic;

    override toQueryString(): string {
        var queryString = super.toQueryString();
        queryString += "&exportType=" + this.exportType;
        return queryString;
    }
}