import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationError } from '@core/models/application-error.model';
import { ExportFleetWebUsersQuery, ExportType } from '@domain/models/filters/export-fleetweb-users-query.model';
import { SearchFleetWebUserQuery } from '@domain/models/filters/search-fleetweb-user-query.model';
import { UserService } from '@domain/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlobHelper } from '@shared/helpers/blob.helper';
import { DateHelper } from '@shared/helpers/date.helper';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-export-users',
  templateUrl: './export-users.component.html',
  styleUrls: ['./export-users.component.scss']
})
export class ExportUsersComponent implements OnInit, OnDestroy {
  @Input() searchQuery : SearchFleetWebUserQuery;
  form: FormGroup;
  exportTypeFormControl: FormControl<ExportType>;
  errorMessage: string;
  
  private _componentSubscriptions = new Array<Subscription>();
  private _exportLoadingHelper = new LoadingHelper();

  constructor(
    public modal: NgbActiveModal,
    private _userService: UserService,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  initForm() {
    this.exportTypeFormControl = new FormControl<ExportType>(ExportType.Basic);

    this.form = this._formBuilder.group({
      exportType: this.exportTypeFormControl
    });    
  }

  public get selectedExportType(): ExportType{
    return this.exportTypeFormControl.value;
  }

  public get isExporting(): boolean{
    return this._exportLoadingHelper.isLoading;
  }

  public get ExportType(){
    return ExportType;
  }

  onExport() {    
    this.errorMessage = null;
    this._exportLoadingHelper.startLoading();
    
    var query = new ExportFleetWebUsersQuery();
    query.email = this.searchQuery.email;
    query.existingUsersOnly = true;
    query.firstName = this.searchQuery.firstName;
    query.frameAgreementId = this.searchQuery.frameAgreementId;
    query.groups = this.searchQuery.groups;
    query.lastName = this.searchQuery.lastName;
    query.socialSecurityNumber = this.searchQuery.socialSecurityNumber;
    query.exportType = this.exportTypeFormControl.value;

    this._userService.exportSearch(query).subscribe({
      next: (data: any) => {
        BlobHelper.openFile(data, `Användare_${DateHelper.nowInSwedishDateFormat}.xlsx`);
        this.modal.close(true);          
      },
      error: error => {
        this._exportLoadingHelper.stopLoading();        
        this.errorMessage = ApplicationError.getMessage(error);
      },
      complete: () =>{
        this._exportLoadingHelper.stopLoading();        
      }
    });
  }
}