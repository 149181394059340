import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Permission } from '@core/models/permissions.enum';
import { AuthenticationService } from '@core/services/authentication.service';
import { DialogService } from '@core/services/dialog.service';
import { CostCenter } from '@domain/models/cost-center.model';
import { Organization } from '@domain/models/organization.model';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { UpdateFleetWebUserOrganizationCostCentersRequest } from '@domain/models/user/update-fleetweb-user-organization-costcenters-request.model';
import { UserCostCenterLimitationInfo } from '@domain/models/user/user-costcenter-limitation-info.model';
import { UserOrganizationInfo } from '@domain/models/user/user-organization-info.model';
import { UserService } from '@domain/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription, debounceTime, map, merge } from 'rxjs';

@Component({
  selector: 'app-edit-user-organization-costcenters-modal',
  templateUrl: './edit-user-organization-costcenters.component.html',
  styleUrls: ['./edit-user-organization-costcenters.component.scss']
})
export class EditUserOrganizationCostcentersComponent implements OnInit, OnDestroy {

  @Input() user: FleetWebUserDetails = null;
  @Input() organization: Organization;
  @Input() initialCostCenterIds: Array<number>;
  
  @Output() costCentersChanged : EventEmitter<UserOrganizationInfo> = new EventEmitter();

  private _loadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();
  
  formGroup: FormGroup;

  noLimitationFormControl: FormControl<boolean>;
  filterFormControl: FormControl<string>;
  costCentersFormArray: FormArray<FormControl<boolean>>;
  
  private _selectedCostCenterIds: Array<number>;
  private _filteredCostCenters: Array<CostCenter>;

  constructor(
    private _userService: UserService,
    private _dialogService: DialogService,
    private _authenticationService: AuthenticationService,
    public modal: NgbActiveModal) { }

  ngOnInit() {
    this._selectedCostCenterIds = this.initialCostCenterIds.map(id => id);
    this._filteredCostCenters = this.organization.costCenters.map(cc => cc);
    this.initForm();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  private initForm() {
    const noLimitation = this.initialCostCenterIds.length === 0;
    this.filterFormControl = new FormControl<string>("");
    this.noLimitationFormControl = new FormControl<boolean>(noLimitation);
    if(!this.canAdministerUsers) {
      this.noLimitationFormControl.disable();
    }

    this._componentSubscriptions.push(this.noLimitationFormControl.valueChanges.subscribe(value => {
      if(value) {
        var selectedFormControls = this.costCentersFormArray.controls.filter(control => {
          return control.value;
        });
        this.removeCostCenters(selectedFormControls, this.selectedCostCenterIds);
        this.filterFormControl.setValue(null);
      }
      else{
        this.patchForm();
      }
    }));

    this._componentSubscriptions.push(this.filterFormControl.valueChanges.pipe(debounceTime(200)).subscribe(_ => {
      this.refreshFilteredCostCenters();
      this.patchForm();
    }));    

    this.costCentersFormArray = new FormArray<FormControl<boolean>>([]);
    if(!noLimitation){
      this.patchForm();
    }

    this.formGroup = new FormGroup({
      noLimitations: this.noLimitationFormControl,
      filter: this.filterFormControl,
      costCenters: this.costCentersFormArray
    });
  }

  get isLoading() {
    return this._loadingHelper.isLoading;
  }

  get noLimitations() {
    return this.noLimitationFormControl.value;
  }

  get hasNoCostCenters(){
    return this.organization.costCenters.length === 0;
  }

  get showFilter(): boolean{
    return this.organization.costCenters.length > 10;
  }

  get showSelectAll(): boolean{
    return this.canAdministerUsers && this.organization.costCenters.length > 1;
  }

  get selectAllDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredCostCenters.every(cc => this._selectedCostCenterIds.find(s => s === cc.v21Sequence) != null);
  }

  get deselectAllDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredCostCenters.every(cc => this._selectedCostCenterIds.every(s => s !== cc.v21Sequence));
  }
   
  get filteredCostCenters(): Array<CostCenter> {
    return this._filteredCostCenters;
  }

  public get canAdministerUsers(): boolean{
    return this._authenticationService.hasAnyPermission([Permission.AdministreraAllaAnvändare, Permission.AdministreraFöretagsanvändare]);
  }

  refreshFilteredCostCenters(){
    if(this.filterFormControl.value){
      var searchString = this.filterFormControl.value;
      this._filteredCostCenters = this.organization.costCenters.filter(costCenter => {
        var isSelected = this._selectedCostCenterIds.filter(id => id === costCenter.v21Sequence).length > 0;
        return isSelected || costCenter.costCenterText.toLowerCase().search(searchString.toLowerCase()) !== -1;
      });
    }
    else{
      this._filteredCostCenters = this.organization.costCenters;
    } 
  }

  get selectedCostCenterIds () : Array<number>{
    return this._selectedCostCenterIds;
  }

  get isFiltering(): boolean{
    return this.filterFormControl.value != null && this.filterFormControl.value !== "";
  }
  
  patchForm(){    
    this.costCentersFormArray.clear();
    this.filteredCostCenters.forEach(costCenter => {
      var isSelected = this._selectedCostCenterIds.filter(id => id === costCenter.v21Sequence).length > 0;
      var control = new FormControl<boolean>(isSelected);
      if(!this.canAdministerUsers){
        control.disable({emitEvent: false});
      }
      this.costCentersFormArray.push(control, {emitEvent: false});
    });

    this.subscribeToCostCenterSelection();
  }

  private refreshForm(){
    this.costCentersFormArray.controls.forEach((formControl, index) => {
      var costCenter = this.filteredCostCenters[index];
      var isSelected = this._selectedCostCenterIds.filter(id => id === costCenter.v21Sequence).length > 0;
      this.setValueWithoutEmitting(formControl, isSelected);
    });
  }

  private subscribeToCostCenterSelection() {
    this._componentSubscriptions.push(merge(...this.costCentersFormArray.controls.map((control: FormControl<boolean>, index: number) =>
      control.valueChanges.pipe(map(value => {        
        return { control: control, value: value, costCenterId: this._filteredCostCenters[index].v21Sequence };
    }))))
    .subscribe(change => {       
      if(change.value){
        this.addCostCenters([change.control], [change.costCenterId]);
       }
      else{
        this.removeCostCenters([change.control], [change.costCenterId]);
      }
    }));
  }

  onClearFilter(){
    this.filterFormControl.setValue(null);
  }
  
  onClose() {
    this.modal.close();
  }

  onSelectAll(){
    this.costCentersFormArray.controls.forEach(control => {
      this.setValueWithoutEmitting(control, true);             
    });         

    var costCentersToAdd = this._filteredCostCenters.filter(f => this._selectedCostCenterIds.findIndex(s => s === f.v21Sequence) === -1).map(f => f.v21Sequence);
    this.addCostCenters(this.costCentersFormArray.controls, costCentersToAdd);
  }

  onDeselectAll(){
    this.costCentersFormArray.controls.forEach(control => {
      this.setValueWithoutEmitting(control, false);      
    });

    var costCentersToRemove = this._filteredCostCenters.filter(f => this._selectedCostCenterIds.findIndex(s => s === f.v21Sequence) !== -1).map(f => f.v21Sequence);
    this.removeCostCenters(this.costCentersFormArray.controls, costCentersToRemove);
  }
  
  private addCostCenters(formControls: Array<FormControl<boolean>>, costCenterIds: Array<number>) {
    if(this._loadingHelper.isLoading){
      formControls.forEach(formControl => {
        this.setValueWithoutEmitting(formControl, false);
      });
      return;
    }
    
    this._loadingHelper.startLoading();

    var request = new UpdateFleetWebUserOrganizationCostCentersRequest();
    request.userId = this.user.userId;
    request.tenantId = this.user.organization.tenantId;
    request.customerId = this.organization.customerId;
    request.costCenterIds = costCenterIds;
    
    this._userService.addOrganizationCostCenters(request).subscribe({
      next: () => {
        this._selectedCostCenterIds = this.selectedCostCenterIds.concat(costCenterIds);
        
        this.emitSelectionChanged();
        
        if(request.userId === this._authenticationService.currentUserValue.sub){
          this._authenticationService.refreshCurrentUser();
        }
      },
      error: (error) => {
        this.refreshForm();
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Lägg till ramavtalsbehörighet till kostnadställe", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
  

  private removeCostCenters(formControls: Array<FormControl<boolean>>, costCenterIds: Array<number>) {    
    if(this._loadingHelper.isLoading){
      formControls.forEach(formControl => {
        this.setValueWithoutEmitting(formControl, true);
      });
      return;
    }

    this._loadingHelper.startLoading();

    var request = new UpdateFleetWebUserOrganizationCostCentersRequest();
    request.userId = this.user.userId;
    request.tenantId = this.user.organization.tenantId;
    request.customerId = this.organization.customerId;
    request.costCenterIds = costCenterIds;
    
    this._userService.removeOrganizationCostCenters(request).subscribe({
      next: () => {
        this._selectedCostCenterIds = this._selectedCostCenterIds.filter(selectedId => {
          return costCenterIds.findIndex(id => id === selectedId) === -1;
        });                

        this.emitSelectionChanged();

        if(request.userId === this._authenticationService.currentUserValue.sub){
          this._authenticationService.refreshCurrentUser();
        }
      },
      error: (error) => {
        this.refreshForm();
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Ta bort ramavtalsbehörighet till kostnadsställe", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
  
  private emitSelectionChanged() {
    var userOrganizationInfo = new UserOrganizationInfo();
    userOrganizationInfo.userCostCenterLimitationInfo = this._selectedCostCenterIds.map(id => {
      var limitation = new UserCostCenterLimitationInfo();
      limitation.id = id;
      return limitation;
    });
    userOrganizationInfo.customerId = this.organization.customerId;
        
    this.costCentersChanged.emit(userOrganizationInfo);
  }

  private setValueWithoutEmitting(control: FormControl<boolean>, value: boolean){
    control.setValue(value, {emitEvent: false});   
  }
}
