<div class="modal-header">
  <h5 class="modal-title">Kostnadsställen för {{organization.name}} </h5>
  <button type="button" class="btn-close" data-dismiss="modal" (click)="onClose()"></button>
</div>
<div class="modal-body">  
  <div class="o-layout">
    <div class="o-layout__item u-1/1 u-1/1@s">    
      <form *ngIf="formGroup" [formGroup]="formGroup">
        <div class="switch mb-2 mt-2">
          <input type="checkbox" formControlName="noLimitations" class="switch" id="noLimitations" />
          <label for="noLimitations">Ingen begränsning</label>
        </div>
        <fieldset class="o-fieldset" *ngIf="!noLimitations">
          <label>Behörig till kostnadsställen</label>
          <div *ngIf="hasNoCostCenters" class="alert alert-info">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-middle">
                <label class="m-0">Kunden har inga kostnadsställen upplagda i affärssystemet.</label>
              </div>
            </div>
          </div>          
          <div *ngIf="showSelectAll" class="mb-3 mt-2">
            <button type="button" class="c-btn c-btn--secondary" (click)="onSelectAll()" [disabled]="selectAllDisabled">
              <span class="c-btn__text">Markera samtliga</span>
            </button>
            <button type="button" class="c-btn c-btn--secondary ms-2" (click)="onDeselectAll()" [disabled]="deselectAllDisabled">
              <span class="c-btn__text">Avmarkera samtliga</span>
            </button>    
          </div>  
          <div *ngIf="showFilter" class="mb-4">
            <label>Filtrera kostnadsställen</label>
            <div class="c-input mb-1">
              <input formControlName="filter" class="c-input__input" type="text" maxlength="255" placeholder="Kostnadställets namn"/>
              <button type="button" class="c-btn c-btn--secondary ms-2" (click)="onClearFilter()" [disabled]="!isFiltering">Rensa</button>
            </div>     
            <small>
              Visar {{filteredCostCenters.length}} av totalt {{organization.costCenters.length}} kostnadsställen. Valda kostnadsställen visas alltid.
            </small> 
          </div>     
          <div>
            <div formArrayName="costCenters" *ngFor="let check of costCentersFormArray.controls; let i = index" class="switch mb-2 mt-2">
              <input type="checkbox" class="switch" [id]="filteredCostCenters[i].id" [formControl]="check" />
              <label [for]="filteredCostCenters[i].id" class="m-0">{{ filteredCostCenters[i].costCenterText }}</label>                
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button type="button" class="c-btn c-btn--secondary" data-dismiss="modal" (click)="onClose()">
      <span class="c-btn__text">Stäng</span>
    </button>    
  </div>
</div>
