import { Injectable } from "@angular/core";
import { ValidationErrorResponse } from "@core/models/validation-error-response.model";
import { BaseApiService } from "@core/services/base-api.service";
import { SearchFleetWebOrganizationQuery } from "@domain/models/filters/search-fleetweb-organization-query.model";
import { AuthwayModuleRequest } from "@domain/models/organization/authway-module-request.model";
import { AuthwayOrganizationInfo } from "@domain/models/organization/authway-organization-info.model";
import { ChangeAuthwayOrganizationNameRequest } from "@domain/models/organization/change-authway-organization-name-request.model";
import { ChangeEntraSettingsRequest } from "@domain/models/organization/change-entra-tenant-id-request.model";
import { ConnectFleetWebOrganizationRequest } from "@domain/models/organization/connect-fleetweb-organization-request.model";
import { ConnectFleetWebOrganizationResponse } from "@domain/models/organization/connect-fleetweb-organization-response.model";
import { CreateAuthwayOrganizationRequest } from "@domain/models/organization/create-authway-organization-request.model";
import { CreateAuthwayOrganizationResponse } from "@domain/models/organization/create-authway-organization-response.model";
import { FleetWebFrameAgreementInfo } from "@domain/models/organization/fleetweb-frame-agreement-info.model";
import { FleetWebOrganizationDetails } from "@domain/models/organization/fleetweb-organization-details.model";
import { FleetWebOrganizationInfo } from "@domain/models/organization/fleetweb-organization-info.model";
import { GetFleetWebOrganizationRequest } from "@domain/models/organization/get-fleetweb-organization-request.model";
import { QueryResult } from "@domain/models/query-result.model";
import { map, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FleetWebOrganizationService {
    
    constructor(private _apiService: BaseApiService) { }

    searchOrganizations(searchQuery: SearchFleetWebOrganizationQuery): Observable<QueryResult<AuthwayOrganizationInfo>> {
      return this._apiService.get(`FleetWebOrganization/Search${searchQuery.toQueryString()}`).pipe(
        map(data => {
          let result = new QueryResult<AuthwayOrganizationInfo>(data, AuthwayOrganizationInfo);
          return result;
        })
      );
    }

    getAuthwayOrganizationsByFrameAgreementId(frameAgreementId: number): Observable<Array<AuthwayOrganizationInfo>>  {
      return this._apiService.get(`FleetWebOrganization/AuthwayOrganizationsByFrameAgreementId/${frameAgreementId}`).pipe(
        map(data => {
          let result = data.map(d => new AuthwayOrganizationInfo(d));
          return result;
        })
      );
    }          

    getByFrameAgreementId(frameAgreementId: number): Observable<FleetWebFrameAgreementInfo> {
      return this._apiService.get(`FleetWebOrganization/ByFrameAgreementId/${frameAgreementId}`).pipe(
          map(data => {
            let result = new FleetWebFrameAgreementInfo(data);
            return result;
          })
        );
    }

    getByOrganizationNumber(organizationNumber: string): Observable<Array<FleetWebOrganizationInfo>> {
      return this._apiService.get(`FleetWebOrganization/ByOrganizationNumber/${organizationNumber}`).pipe(
          map(data => {
            let result = data.map(d => new FleetWebOrganizationInfo(d));
            return result;
          })
        );
    }

    getDetails(request: GetFleetWebOrganizationRequest): Observable<FleetWebOrganizationDetails> {
      return this._apiService.get(`FleetWebOrganization/Details/`+request.toQueryString()).pipe(
          map(data => {
            let result = new FleetWebOrganizationDetails(data);
            return result;
          })
        );
    }

    createAuthwayOrganization(request: CreateAuthwayOrganizationRequest) :Observable<CreateAuthwayOrganizationResponse | ValidationErrorResponse> {
      return this._apiService.post(`FleetWebOrganization/CreateAuthwayOrganization`, request).pipe(        
        map(data => {
          if (data instanceof ValidationErrorResponse) {
            return data;
          }

          let result = new CreateAuthwayOrganizationResponse(data);
          return result;
        })
      );
    }

    changeAuthwayOrganizationName(request: ChangeAuthwayOrganizationNameRequest) : Observable<ValidationErrorResponse> {
      return this._apiService.put(`FleetWebOrganization/ChangeAuthwayOrganizationName`, request);
    }
        
    connectFleetWebOrganization(request: ConnectFleetWebOrganizationRequest) : Observable<ConnectFleetWebOrganizationResponse | ValidationErrorResponse>{
      return this._apiService.put(`FleetWebOrganization/Connect`, request).pipe(
        map(data => {
          if (data instanceof ValidationErrorResponse) {
            return data;
          }

          let result = new ConnectFleetWebOrganizationResponse(data);
          return result;
        })
      );
    }  

    activateFleetModule(request: AuthwayModuleRequest) : Observable<ValidationErrorResponse>{
      return this._apiService.put(`FleetWebOrganization/ActivateFleetModule`, request);
    }    
    
    inactivateFleetModule(request: AuthwayModuleRequest) : Observable<ValidationErrorResponse>{
      return this._apiService.put(`FleetWebOrganization/InactivateFleetModule`, request);
    }   
        
    changeEntraSettings(request: ChangeEntraSettingsRequest) : Observable<ValidationErrorResponse>{
      return this._apiService.put(`FleetWebOrganization/ChangeEntraSettings`, request);
    }   
}
