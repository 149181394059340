import { Permission } from "./permissions.enum";

export class PermissionGroups {
  public static ReportPermissions: Array<Permission> = [
    Permission.RapportBruttolöneavdrag,
    Permission.RapportKostnadsöversiktFörare,
    Permission.RapportKostnadsöversiktFöretag,
    Permission.RapportVagnsparksöversikt,
    Permission.RapportTotalaKostnader,
    Permission.RapportMiljörapport,
    Permission.RapportRapporteradKörsträcka,
    Permission.RapportTransaktionslista,
    Permission.RapportFörmånsvärdesammanställning,
    Permission.RapportLöneunderlag,
    Permission.RapportSplittberäkningFörare
  ];

  public static All: Array<Permission> = Object.values(Permission);

  public static InternalUserPermissions: Array<Permission> = [
    Permission.AdministreraSamarbetspartners,
    Permission.AdministreraAssistansbolag,
    Permission.AdministreraVanligaFrågor,
    Permission.AdministreraInformationOchBlanketter,
    Permission.AdministreraNyheter,
    Permission.AdministreraStatusExternKörjournalsrapportering,
    Permission.AdministreraExternaKörjournalsrapporter,
    Permission.KörSomAnnan,
    Permission.AdministreraAllaAnvändare
  ];

  public static get ExternalUserPermissions(): Array<Permission> {
    return this.All.filter((item) => {
      return this.InternalUserPermissions.findIndex(internal => internal.toString() == item) == -1;
    });
  }

  public static get AdministrationPermissions(): Array<Permission> {
    return this.InternalUserPermissions.filter((item) => {
      return  item != Permission.KörSomAnnan;
    }); 
  }

  public static SupportCasePermissions: Array<Permission> = [
    Permission.LäsaÄndraFörareÄrende,
    Permission.LäsaÄndraKostnadsställeÄrende,
    Permission.LäsaÄndraBilkategoriÄrende,
    Permission.LäsaBeställDrivmedelskortÄrende,
    Permission.LäsaÄndraFöraruppgifterÄrenden,
    Permission.SkapaÄndraFörareÄrende,
    Permission.SkapaÄndraKostnadsställeÄrende,
    Permission.SkapaÄndraBilkategoriÄrende,
    Permission.SkapaBeställDrivmedelskortÄrende,
    Permission.SkapaÄndraFöraruppgifterÄrendeAllaUppgifter,
    Permission.SkapaÄndraFöraruppgifterÄrendeEndastAnställningsnummer
  ];

  public static ReadSupportCasePermissions: Array<Permission> = [
    Permission.LäsaÄndraFörareÄrende,
    Permission.LäsaÄndraKostnadsställeÄrende,
    Permission.LäsaÄndraBilkategoriÄrende,
    Permission.LäsaBeställDrivmedelskortÄrende,
    Permission.LäsaÄndraFöraruppgifterÄrenden
  ];

  public static CreateSupportCasePermissions: Array<Permission> = [
    Permission.SkapaÄndraFörareÄrende,
    Permission.SkapaÄndraKostnadsställeÄrende,
    Permission.SkapaÄndraBilkategoriÄrende,
    Permission.SkapaBeställDrivmedelskortÄrende,
    Permission.SkapaÄndraFöraruppgifterÄrendeAllaUppgifter,
    Permission.SkapaÄndraFöraruppgifterÄrendeEndastAnställningsnummer
  ];

  public static CompanyPermissions: Array<Permission> = [
    Permission.Vagnparksöversikt,
    Permission.LäsaFöretagsbilarSomLöperUt,
    Permission.AdministreraAvrop,
    Permission.LäsaFakturor
  ]
}
