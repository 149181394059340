import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-frame-agreement-search-advanced',
  templateUrl: './frame-agreement-search-advanced.component.html',
  styleUrls: ['./frame-agreement-search-advanced.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FrameAgreementSearchAdvancedComponent
    }
  ]
})
export class FrameAgreementSearchAdvancedComponent implements OnInit, OnDestroy, ControlValueAccessor {
  formGroup: FormGroup;
  private includeDisabledFormControl: FormControl<boolean>;
  private frameAgreementFormControl: FormControl<FrameAgreement>;
  private _componentSubscriptions = new Array<Subscription>();
  private _includeDisabled = false;

  onChange = (_: FrameAgreement) => { };
  onTouched = () => { };

  uniqueId: string;

  constructor(private parentFormGroup: FormGroupDirective = null) {
    this.uniqueId = "switch-inactive" + Date.now();
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });

    this._componentSubscriptions.splice(0);
  }

  get includeDisabled(): boolean {
    if(this.includeDisabledFormControl) {
        return this.includeDisabledFormControl.value;
    }
    else{
        return this._includeDisabled;
    }
  }
  @Input()
  set includeDisabled(value: boolean){
    if(this.includeDisabledFormControl){
        this.includeDisabledFormControl.setValue(value);
    }
    else{
        this._includeDisabled = value;
    }
  }

  private initForm() {
    this.frameAgreementFormControl = new FormControl<FrameAgreement>(null);    
    this.includeDisabledFormControl = new FormControl<boolean>(this._includeDisabled);

    this._componentSubscriptions.push(this.frameAgreementFormControl.valueChanges.subscribe(value => {      
        this.onSelected(value);      
    }));

    this.formGroup = new FormGroup({
      frameAgreement: this.frameAgreementFormControl,
      includeDisabled: this.includeDisabledFormControl
    });

    if (this.parentFormGroup != null) {
      this.parentFormGroup.control.addControl("formGroup", this.formGroup);
    }
  }

  writeValue(obj: any): void {
    if (obj instanceof (FrameAgreement)) {
      this.frameAgreementFormControl.setValue(obj, {emitEvent:false});
    }
    else {
      this.frameAgreementFormControl.setValue(null, {emitEvent:false});
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    if (disabled) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }

  onSelected(frameAgreement: FrameAgreement) {
    this.markAsTouched();
    this.onChange(frameAgreement);
  }

  markAsTouched() {
    if (!this.formGroup.touched) {
      this.onTouched();
      this.formGroup.markAsTouched();
    }
  }

  reset() {
    this.formGroup.reset();
    this.includeDisabledFormControl.setValue(false);
  }
}
