<div id="super-user-menu" [class.super-user-menu-wide]="hover" (mouseover)="hover=true" (mouseout)="hover=false">
  <div class="d-flex flex-column justify-content-between h-100">
    <ul>
      <ng-container>

        <li>
          <div class="super-user-text-admin mt-4">
            <span class="super-user-icon-text text-grey" [hidden]="!hover">
              Administrera
            </span>
          </div>
        </li>

        <ng-container *ngFor="let parentRoute of routeMetadata.SuperUser.children | keyvalue:keepOrder; let parentIndex = index">
          <ng-container *ngIf="parentRoute.value.data && parentRoute.value.data.showInMenu">
            <ng-container *ngIf="parentRoute && parentRoute.value.data && parentRoute.value.data.showInMenu">
              <app-menu-item [hover]="hover" [routerLinkActive]="'super-user-icon-active'" [route]="parentRoute"></app-menu-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>     
    </ul>
  </div>
</div>
