import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { GetNewsArticleResponse } from "@domain/models/news";
import { NewsService } from "../../../domain/services/news.service";
import { ApplicationService } from "@domain/services/application.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  
  public frontPageNews: GetNewsArticleResponse[];  
  public errorMessage: string;    
  private _applicationOnline = null;
  
  constructor(
    private router: Router,
    private _authenticationService: AuthenticationService,
    private _newsService: NewsService,
    private _applicationService: ApplicationService
  ) { }

  ngOnInit() {

    if (this._authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
      return;
    }

    this.getFrontPageNews();
    this.getApplicationOnlineStatus();
  }

  get showFrontPageNews () {
    return this.hasFrontPageNews;
  }

  get hasFrontPageNews () {
    return this.frontPageNews && this.frontPageNews.length > 0;
  }

  get canSignIn(){
    return this.applicationOnline;
  }
  
  get applicationOnline(){
    return this._applicationOnline;
  }

  getFrontPageNews() {
    this.frontPageNews = [];
    this._newsService.getFrontPageNews().subscribe({
      next: data => {
        this.frontPageNews = data;    
      },
      error: () => {
        //Intentionally not displaying error messages to the user when fetching front page news fails.        
      },
      complete: () => {

      }
    });
  }

  getApplicationOnlineStatus() {
    this.errorMessage = "";
    this._applicationService.getStatus().subscribe({
      next: data => {
        this._applicationOnline = data;    
        if(!this._applicationOnline) {
          this.errorMessage = "FleetWeb är tillfälligt stängt för inloggning.";
        }
      },
      error: () => {
        //Intentionally not displaying error messages to the user when fetching online status fails.        
      },
      complete: () => {

      }
    });
  }
   
  signIn(){
    this._authenticationService.signIn();
  }
}
