import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl,  FormGroup, Validators } from '@angular/forms';
import { FrameAgreement } from '@domain/models/frame-agreement.model';

@Component({
    selector: 'app-frame-agreement-selector',
    templateUrl: './frame-agreement-selector.component.html',
    styleUrls: ['./frame-agreement-selector.component.scss']
  })
export class FrameAgreementSelectorComponent implements OnInit {
    
    @Input() frameAgreement: FrameAgreement = null;
    @Output() newValue: EventEmitter<FrameAgreement> = new EventEmitter();

    formGroup: FormGroup;
    frameAgreementFormControl: FormControl<FrameAgreement>;
  
    ngOnInit() {        
        this.initForm();   
    }
    
    get isEditing(){
        return !this.frameAgreementFormControl.disabled;
    }

    get isError(){
        return this.frameAgreementFormControl.dirty && this.frameAgreementFormControl.errors;
    }
    
    private initForm() {
        this.frameAgreementFormControl = new FormControl<FrameAgreement>(this.frameAgreement, [Validators.required]);
        this.frameAgreementFormControl.disable();
    
        this.formGroup = new FormGroup({
            frameAgreement: this.frameAgreementFormControl
        });
    }

    onEdit(){
        this.frameAgreementFormControl.enable();
    }
    
    onCancel(){
        this.frameAgreementFormControl.setValue(this.frameAgreement);        
        this.frameAgreementFormControl.disable();
    }

    onSave(){
        if(!this.formGroup.valid)
        {
            this.frameAgreementFormControl.markAsDirty();
            return;
        }

        var newValue = this.frameAgreementFormControl.value;
        this.newValue.emit(newValue);
        
        this.frameAgreementFormControl.reset();
        this.frameAgreementFormControl.disable();           
        this.frameAgreementFormControl.setValue(newValue);     
    }
}
