import { FrameAgreement } from "../frame-agreement.model";

export class ConnectFleetWebOrganizationResponse{
    constructor(jsonObject: any = null) {

        if (jsonObject == null) {
          return;
        }
    
        this.connectedFrameAgreement = jsonObject.connectedFrameAgreement ? new FrameAgreement(jsonObject.connectedFrameAgreement) : null;
    }
    
    connectedFrameAgreement: FrameAgreement;
}
    