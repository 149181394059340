export class EntraSettings{
    constructor(jsonObject: any = null) {

        if (jsonObject == null) {
          return;
        }
    
        this.entraTenantId = jsonObject.entraTenantId;
        this.displayName = jsonObject.displayName;
        this.allowed = jsonObject.allowed;
    }
    
    entraTenantId: string;
    displayName: string;
    allowed: boolean;
}