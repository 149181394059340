import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from "@angular/core";
import { UntypedFormGroup, FormControl, FormGroupDirective, ReactiveFormsModule, ControlValueAccessor } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { Subscription, Observable, OperatorFunction, of, debounceTime, distinctUntilChanged, filter, map, tap, switchMap, catchError } from "rxjs";
import { LoadingHelper } from "@shared/helpers/loading.helper";
import { QueryResult } from "@domain/models/query-result.model";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  template: ''
})
export abstract class TypeAheadSearchComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() clearSearchOnSelectedMatch = false;

  @Output() foundResults = new EventEmitter<number>();
  @Output() loadingChanged = new EventEmitter<boolean>();

  onChange = (_: T) => { };
  onTouched = () => { };

  private _loadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();

  private _formGroup: UntypedFormGroup;
  private _searchFormControl: FormControl<T>;

  private _searchEmpty: boolean;
  private _searching = false;
  private _searchFailed = false;
  private _searchTerm: string;

  private _totalCount = null;
  private _resultCount = null;
  private _matches: Array<T> = null;

  private _searchFormControlValueChangesSubscription: Subscription;

  constructor(private parentFormGroup: FormGroupDirective = null) {
    this._componentSubscriptions.push(this._loadingHelper.loadingChanged.subscribe((value) => {
      this.emitLoadingChanged(value);
    }));
  }

  ngOnInit() {
    this._searchFormControl = new FormControl<T>(null);

    this._formGroup = new UntypedFormGroup({
      search: this._searchFormControl
    });

    if (this.parentFormGroup != null) {
      this.parentFormGroup.control.addControl("formGroup", this._formGroup);
    }

    this.subscribeToValueChanges();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);

    this.unSubscribeFromValueChanges();
  }

  public get formGroup() {
    return this._formGroup;
  }

  public get searching() {
    return this._searching;
  }

  public get isLoading(): boolean {
    return this._loadingHelper.isLoading;
  }

  public get searchFailed(): boolean {
    return this._searchFailed;
  }

  public get searchEmpty(): boolean {
    return this._searchEmpty;
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public search: OperatorFunction<string, readonly T[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => {
        this._searchEmpty = false;
        return term.toString().length >= this.minSearchTermLength;
      }),
      tap((term) => {
        this._searchTerm = term;
        this._searching = true;
        this._loadingHelper.startLoading();
      }),
      switchMap(term => {
        return this.searchAction(term).pipe(
          map(data => {
            this._totalCount = data.total;
            this._resultCount = data.result.length;
            this._matches = data.result;

            this._searchFailed = false;

            if (data.result.length === 0) {
              this._searchEmpty = true;
            }

            this.emitFoundResults(data.result.length);
            return data.result;
          }),
          catchError((_) => {
            this._searchFailed = true;
            return of([]);
          }));
      }),
      tap(() => {
        this._searching = false;
        this._loadingHelper.reset();
      })
    );

  public inputFormatter = (match: T): string => {
    if (!match) {
      return "";
    }

    return this.formattedAsText(match);
  };

  public reset() {
    this._searchFormControl.reset();
    this._searching = false;
    this._searchFailed = false;
    this._searchEmpty = false;
  }

  public isLastItemInList(match: T) {
    var lastPart = this._matches[this._matches.length - 1];
    return lastPart == match;
  }

  public get showRefineSearch(): boolean {
    return this._totalCount > this._resultCount;
  }

  public get resultText() {
    return this.formattedResultText(this._resultCount, this._totalCount);
  }

  public get searchInstruction() {
    return this.formattedSearchInstruction(this.minSearchTermLength);
  }

  public abstract get placeholderText(): string;

  public abstract get minSearchTermLength(): number;

  protected abstract formattedAsText(match: T): string;

  protected abstract formattedResultText(resultCount: number, totalCount: number): string;

  protected abstract formattedSearchInstruction(minSearchTermLength: number): string;

  protected abstract searchAction(term: string): Observable<QueryResult<T>>;

  protected abstract isInstanceOfType(value: T): boolean;

  protected abstract strikeThroughText(match: T): boolean;

  private emitLoadingChanged(loading: boolean) {
    this.loadingChanged.emit(loading);
  }

  private emitFoundResults(count: number) {
    this.foundResults.emit(count);
  }

  private subscribeToValueChanges(){
    this._searchFormControlValueChangesSubscription = this._searchFormControl.valueChanges
    .subscribe((match: T) => {
      if (match != null && this.isInstanceOfType(match)) {
        this.onSelected(match);
        if (this.clearSearchOnSelectedMatch) {
          this.reset();
        }
      }
      else {
        this.onSelected(null);
      }
    });
  }

  private unSubscribeFromValueChanges(){
    if(this._searchFormControlValueChangesSubscription){
      this._searchFormControlValueChangesSubscription.unsubscribe();
    }
  }

  writeValue(obj: T): void {
    this.unSubscribeFromValueChanges();
    this._searchFormControl.setValue(obj as T);
    this.subscribeToValueChanges();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    if (disabled) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }

  markAsTouched() {
    if (!this.formGroup.touched) {
      this.onTouched();
      this.formGroup.markAsTouched();
    }
  }

  onSelected(selectedMatch: T) {
    this.markAsTouched();
    this.onChange(selectedMatch);
  }
}
