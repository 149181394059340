import { Component, Output, EventEmitter, HostBinding } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { WizardComponent } from "@shared/components/wizard/wizard.component";
import { LoggingService } from "@core/services/logging.service";
import { DialogService } from "@core/services/dialog.service";

export enum WizardNavigationOption {
  Reset,
  Next,
  Previous,
  Finish
}

@Component({
  template: ''
})
export class WizardStepContentComponent {

  @HostBinding('class.inherit-flex') inheritFlexClass = true;

  @Output() resetWizardEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() previousStepEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() errorEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() submitEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() finishEvent: EventEmitter<boolean> = new EventEmitter();

  hasChanged: boolean;

  form: UntypedFormGroup;
  private wizard: WizardComponent;

  get Wizard() { return this.wizard; }
  set Wizard(value: WizardComponent) { this.wizard = value; }

  get f() { return this.form ? this.form.controls : null; }

  constructor(
    protected _loggingService: LoggingService, 
    protected _dialogService: DialogService) {
  }

  reset(){
    this.resetWizardEvent.emit(true);
  }

  previousStep() {
    this.previousStepEvent.emit(true);
  }

  submit() {
    this.submitEvent.emit(true);
  }

  finish() {
    this.finishEvent.emit(true);
  }

  tryFinish() {
    //Override this method to handle attempts to finish wizard.
    this._loggingService.logDebug('Kommer in i tom tryFinish');
  }

  save(_: () => void): void {
    this._loggingService.logDebug('Kommer in i tom save');
  }

  navigate(option: WizardNavigationOption) {

    switch (option) {
      case WizardNavigationOption.Reset:
        this.reset();
        break;
      case WizardNavigationOption.Next:
        this.submit();
        break;
      case WizardNavigationOption.Previous:
        this.previousStep();
        break;
      case WizardNavigationOption.Finish:
        this.finish();
        break;
    }
  }
}
