import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from "@angular/router";
import { Subscription, filter } from 'rxjs';
import { AuthenticationService } from "../../services/authentication.service";
import { ScrollService } from "../../services/scroll.service";

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  public breadcrumbs: IBreadcrumb[];

  private _componentSubscriptions = new Array<Subscription>();
  private subscription: Subscription;

  showScrollButton = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() 
  {
    const viewportHeight = window.innerHeight;
    const threshold = viewportHeight * 2;
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (currentScrollPosition > threshold) {
      this.showScrollButton = true;
    } else {
      this.showScrollButton = false;
    }
  }
  
  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private scrollService: ScrollService) {

    this.breadcrumbs = [];
    this._componentSubscriptions.push(
      this.subscription = this.scrollService.scrollToTop$.subscribe(() => this.scrollToTop())
    );
  }

  currentYearDate: Date;
  currentYearValue: number;

  ngOnInit() {
    this.currentYearDate = new Date();    
    this.currentYearValue = this.currentYearDate.getFullYear();
    
    //subscribe to the NavigationEnd event
    this._componentSubscriptions.push(this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
      //set breadcrumbs
      let root: ActivatedRoute = this._route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    }));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
        s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);  
  }

  get loggedIn() {
     return this._authenticationService.currentUserValue != null 
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "title";

    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!Object.prototype.hasOwnProperty.call(child.snapshot.data, ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      url = child.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join('/');

      let label = child.snapshot.data[ROUTE_DATA_BREADCRUMB]

      child.snapshot.paramMap.keys.forEach(param => {
        url += "/" + child.snapshot.params[param];
      });

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: label,
        params: child.snapshot.params,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
