import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { DialogService } from '@core/services/dialog.service';
import { UserService } from '@domain/services/user.service';
import { QueryResult } from '@domain/models/query-result.model';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { SearchFleetWebUserQuery } from '@domain/models/filters/search-fleetweb-user-query.model';
import { FleetWebUserInfo } from '@domain/models/user/fleetweb-user-info.model';
import { HeroModel } from '@domain/models/hero.model';
import { HeroService } from '@domain/services/hero.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { Permission } from '@core/models/permissions.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FrameAgreementSearchAdvancedComponent } from '@shared/components/frame-agreement-search-advanced/frame-agreement-search-advanced.component';
import { PaginationComponent, PaginationSettings } from '@shared/components/pagination/pagination.component';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ForceLogoutComponent } from '../force-logout/force-logut.component';
import { ExportUsersComponent } from '../export-users/export-users.component';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @ViewChild(FrameAgreementSearchAdvancedComponent) frameAgreementSearchAdvancedComponent: FrameAgreementSearchAdvancedComponent;
  @ViewChild(PaginationComponent) pagination: PaginationComponent;
  
  
  heroModel: HeroModel;
  form: UntypedFormGroup;
  
  frameAgreementControl: FormControl<FrameAgreement>;
  groupsFormArray: FormArray<FormControl<boolean>>;
  firstNameControl: FormControl<string>;
  lastNameControl: FormControl<string>;
  socialSecurityNumberControl: FormControl<string>;
  emailControl: FormControl<string>;
  searchModeFromControl: FormControl<SearchMode>;
  
  private _searchFleetWebUsersQuery = new SearchFleetWebUserQuery();
  private _searchResult: QueryResult<FleetWebUserInfo> = null;

  private _componentSubscriptions = new Array<Subscription>();
  private _searchLoadingHelper = new LoadingHelper();
  private _groupsLoadingHelper = new LoadingHelper();

  private _selectedFrameAgreementId: number = null;
  groups = new Array<string>();
  
  paginationSettings = new PaginationSettings(0, 1, 10);

  SearchMode = SearchMode;
  UserAction = UserAction;

  constructor(  
    private _heroService: HeroService,  
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _localStorageService: LocalStorageService,
    private _modalService: NgbModal,
    private _dialogService: DialogService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute) { 
    
      this.initHero();
  }

  ngOnInit() {   
    this.initFilterModel();
    this.initForm();    
    this.performSearch();    
    this.getGroups(true);
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
        s.unsubscribe();
      });
      this._componentSubscriptions.splice(0);  
  }

  private initHero() {
    this.heroModel = new HeroModel();
    this.heroModel.title = "Användare";
    this.heroModel.subtitle = "Översikt";
    
    var canForceLogout = this._authenticationService.hasPermission(Permission.LoggaUtAnvändare);
    if(canForceLogout)
    {
      this.heroModel.buttonText = "Logga ut alla användare";
    }

    this._heroService.set(this.heroModel);    

    if(canForceLogout){
      this._componentSubscriptions.push(this._heroService.clickEvent.subscribe(() => {
       this.showForceLogoutDialog();
      }));      
    }
  }
    
  private initFilterModel() {    
    var savedQuery = this.canAdministrateAllUsers ? this._localStorageService.searchFleetWebUserQuery :  this._localStorageService.searchCompanyFleetWebUserQuery;    
    this._searchFleetWebUsersQuery = savedQuery == null ? new SearchFleetWebUserQuery() : savedQuery;
    this.paginationSettings.page = this._searchFleetWebUsersQuery.page;
    this.paginationSettings.pageSize = this._searchFleetWebUsersQuery.pageSize;    
  }

  private initForm() {
    this.frameAgreementControl = new FormControl<FrameAgreement>(this._searchFleetWebUsersQuery.frameAgreement); 
    this.firstNameControl = new FormControl<string>(this._searchFleetWebUsersQuery.firstName);
    this.lastNameControl = new FormControl<string>(this._searchFleetWebUsersQuery.lastName);
    this.socialSecurityNumberControl = new FormControl<string>(this._searchFleetWebUsersQuery.socialSecurityNumber);
    this.emailControl = new FormControl<string>(this._searchFleetWebUsersQuery.email);
    this.searchModeFromControl = new FormControl<SearchMode>(this._searchFleetWebUsersQuery.existingUsersOnly ? SearchMode.Existing : SearchMode.Potential);
    this.groupsFormArray = new FormArray<FormControl<boolean>>([]);

    this._componentSubscriptions.push(this.frameAgreementControl.valueChanges.subscribe(value => {
      if(this._selectedFrameAgreementId !== value?.id){        
        this.getGroups(true);  
      }
      this._selectedFrameAgreementId = value?.id;
    }));
    
    this.form = this._formBuilder.group({
      frameAgreement: this.frameAgreementControl,
      groups: this.groupsFormArray,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      socialSecurityNumber : this.socialSecurityNumberControl,
      email: this.emailControl,
      searchMode: this.searchModeFromControl
    });    
  }

  public get isLoading(): boolean{
    return this._searchLoadingHelper.isLoading;
  }

  get searchMode(): SearchMode{
    return this.searchModeFromControl.value;
  }

  public get canAdministrateAllUsers(): boolean{
    return this._authenticationService.hasPermission(Permission.AdministreraAllaAnvändare);
  }
  
  public get canAdministrateUsers(): boolean{
    return this._authenticationService.hasAnyPermission([Permission.AdministreraAllaAnvändare, Permission.AdministreraFöretagsanvändare]);
  }

  public get canImpersonate(): boolean{
    return this._authenticationService.hasPermission(Permission.KörSomAnnan);
  }

  public get isGroupsLoading(): boolean{
    return this._groupsLoadingHelper.isLoading;
  }

  public get users() : Array<FleetWebUserInfo>{
    return this._searchResult?.result;
  }

  public get showCompanyDisclaimer(): boolean{
    return !this.canAdministrateAllUsers && this.searchMode === SearchMode.Potential;
  }

  public get showSuperUserDisclaimer(): boolean{
    return this.canAdministrateAllUsers && this.searchMode === SearchMode.Potential && this.frameAgreementControl.value != null;
  }

  get includeDisabled(): boolean{
    return this._searchFleetWebUsersQuery.includeDisabledFrameAgreements;
  }

  get canExport(): boolean{
    return this._searchFleetWebUsersQuery.existingUsersOnly;
  }

  public get isImpersonating(): boolean{
    return this._authenticationService.currentUserValue.isImpersonating;
  }

  public getUserAction(user: FleetWebUserInfo) : UserAction{ 
    if(user.userId === null) {
      if(this.canAdministrateAllUsers) {
        if(user.userName === "" || user.userName == null || 
          user.hasMultipleFrameAgreementCommitment ||
          user.partId == null){
            return UserAction.CreatePrevented;
        }
        return UserAction.Create;
      }

      return UserAction.None;
    }

    if(!this.canAdministrateUsers) {
      return UserAction.View;
    }    

    return UserAction.Edit;    
  }

  public getUserActionText(user: FleetWebUserInfo): string{
    var userAction = this.getUserAction(user);

    switch(userAction){
      case UserAction.View:
        return "Visa";
      case UserAction.Create:
      case UserAction.CreatePrevented:
        return "Skapa";
      case UserAction.Edit:
        return "Redigera"; 
      case UserAction.None:
      default:
        return "";
    }
  }

  public onUserAction(user: FleetWebUserInfo){
    var userAction = this.getUserAction(user);

    switch(userAction){
      case UserAction.Create:
        this.showCreateUserDialog(user);
        break;
      case UserAction.View:
      case UserAction.Edit:
        this._router.navigate([`edit/${user.userId}`], { relativeTo: this._route });
        break;      
    }
  }

  showCreateUserDialog(userInfo: FleetWebUserInfo) {
    const modalRef = this._modalService.open(
      CreateUserComponent,
      { backdrop: "static", size: "lg", animation: false}
    );

    modalRef.componentInstance.userInfo = userInfo;

    modalRef.result.then(userId => {
      if (userId) {
        this._router.navigate([`edit/${userId}`], {relativeTo: this._route});        
      }
    });
  }

  showForceLogoutDialog() {
    const modalRef = this._modalService.open(
      ForceLogoutComponent,
      { backdrop: "static", size: "sm", animation: false }
    ); 
    
    modalRef.result.then(result => {        
      if(result)
        this._authenticationService.handleUnAuthorizedUser();
    });
  }

  public getCreatePreventedReason(user: FleetWebUserInfo){
    if(user.userName === "" || user.userName == null) {
      return "Fleet kontaktadress e-post saknas i affärssystemet.";
    }
    else if(user.hasMultipleFrameAgreementCommitment){
      return "Personen ligger på flera ramavtal i affärssystemet."
    }
    else if(user.partId == null){
      return "Personen är inte synkroniserad i affärssystemet."
    }
    else{
      return "";
    }
  }
  
  public onReset(){
    this.frameAgreementControl.setValue(null, {emitEvent: false});   

    this.firstNameControl.setValue(null);
    this.lastNameControl.setValue(null)
    this.socialSecurityNumberControl.setValue(null)
    this.emailControl.setValue(null)
    this.searchModeFromControl.setValue(SearchMode.Existing);    

    this.getGroups(false);
  }
  
  public onSubmit(){
    this._searchFleetWebUsersQuery.page = 1;

    this._searchFleetWebUsersQuery.firstName = this.firstNameControl.value?.trim();
    this._searchFleetWebUsersQuery.email = this.emailControl.value?.trim();
    this._searchFleetWebUsersQuery.lastName = this.lastNameControl.value?.trim();
    this._searchFleetWebUsersQuery.existingUsersOnly = this.searchModeFromControl.value === SearchMode.Existing;
    this._searchFleetWebUsersQuery.frameAgreementId = this.frameAgreementControl.value?.id;
    this._searchFleetWebUsersQuery.socialSecurityNumber = this.socialSecurityNumberControl.value?.trim();
    this._searchFleetWebUsersQuery.groups = this.searchModeFromControl.value === SearchMode.Existing ? this.getSelectedGroups() : new Array<string>();
    
    // Only used in client:
    if(this.canAdministrateAllUsers){
      this._searchFleetWebUsersQuery.includeDisabledFrameAgreements = this.frameAgreementSearchAdvancedComponent.includeDisabled;
      this._searchFleetWebUsersQuery.frameAgreement = this.frameAgreementControl.value;
    }
        
    this.performSearch();
  }
 
  onPageChanged(page: number) {
    this._searchFleetWebUsersQuery.page = page;
    this.performSearch();
  }

  onPageSizeChanged(pageSize: number) {
     this._searchFleetWebUsersQuery.pageSize = pageSize;
     this._searchFleetWebUsersQuery.page = 1;
     this.performSearch();
  }

  onImpersonate(user: FleetWebUserInfo){
    if(this.isImpersonating){
      return;
    }

    this._userService.exists(user.userId).subscribe({
      next: result => {
        if(result){
          var modalRef = this._dialogService.showConfirm("Kör som...", `Vill du köra FleetWeb som användaren ${user.firstName} ${user.lastName}?<br/><br/>${user.userName}`, "Ja", "Avbryt");
          modalRef.result.then(result => {        
            if(result){
              this._authenticationService.impersonate(user.userId); 
            }                
          });
        }
        else{
          this._dialogService.showInfoMessage(
            "Användaren kunde inte hittas i Authway.", "Du kan välja att ta bort användaren även från FleetWeb när du redigerar den.",
          );
        }
      },
      error: (error) => {
        this._dialogService.showError(error, "Kontrollera att användare finns", false);        
      },
      complete: () => {
        
      }
    });    
  }

  
  performSearch() {    
    this._searchLoadingHelper.startLoading();
    
    if(this.canAdministrateAllUsers){
      this._localStorageService.searchFleetWebUserQuery = this._searchFleetWebUsersQuery;
    }
    else{
      this._localStorageService.searchCompanyFleetWebUserQuery = this._searchFleetWebUsersQuery;
    }

    this._userService.search(this._searchFleetWebUsersQuery).subscribe({
      next: (result) => {        
        this._searchResult = result;     
        this.paginationSettings = new PaginationSettings(result.total, this._searchFleetWebUsersQuery.page, this._searchFleetWebUsersQuery.pageSize);        
      },
      error: (error) => {
        this._searchLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Sök kundernas användare");
      },
      complete: () => {
        this._searchLoadingHelper.stopLoading();
      }
    });
  }

  onExportToExcel(){
    const modalRef = this._modalService.open(
      ExportUsersComponent,
      { backdrop: "static", size: "sm", animation: false }
    ); 
    
    modalRef.componentInstance.searchQuery = this._searchFleetWebUsersQuery;
    modalRef.result;    
  }

  getGroups(selectQueryValues: boolean){
    this._groupsLoadingHelper.startLoading();
    this.groupsFormArray.clear();
    this.groups = new Array<string>();
    var frameAgreementId = this.frameAgreementControl.value?.id;

    this._userService.getGroups(frameAgreementId).subscribe({
      next: (result) => {        
        this.groups = result;            
        this.groups.forEach((gr) => {
          var checked = selectQueryValues && this._searchFleetWebUsersQuery.groups.findIndex(g => g === gr) !== -1;
          this.groupsFormArray.push(new FormControl<boolean>(checked));
        });     
      },
      error: (error) => {
        this._groupsLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Hämta användargrupper");
      },
      complete: () => {
        this._groupsLoadingHelper.stopLoading();
      }
    });
  }

  private getSelectedGroups() : Array<string> {
    var groups = new Array<string>();
    this.groupsFormArray.controls.forEach((control, index) => {
      if (control.value) {
        var group = this.groups[index];
        groups.push(group);
      }
    });
    return groups;
  }
}

export enum SearchMode {
  Existing,
  Potential
}

export enum UserAction {
  None,
  View,
  Create,
  CreatePrevented,
  Edit
}
