import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { QueryResult } from '@domain/models/query-result.model';
import { SearchBusinessSystemPersonQuery } from '@domain/models/user/search-business-system-person-query.model';
import { BusinessSystemConnectionInfo } from '@domain/models/user/business-system-connection-info.model';
import { BusinessSystemPerson } from '@domain/models/business-system-person.model';

@Injectable({
    providedIn: 'root'
})
export class BusinessSystemPersonService {
  
  constructor(
    private _apiService: BaseApiService) { }

  searchBusinessSystemPerson(searchQuery: SearchBusinessSystemPersonQuery) : Observable<QueryResult<BusinessSystemConnectionInfo>> {
    return this._apiService.get(`BusinessSystemPerson/Search${searchQuery.toQueryString()}`).pipe(
      map(data => {
        return new QueryResult<BusinessSystemConnectionInfo>(data, BusinessSystemConnectionInfo);
      })
    );
  }

  get(customerId:number): Observable<BusinessSystemPerson>{
    return this._apiService.get(`BusinessSystemPerson/${customerId}`).pipe(
      map(data => {
        return new BusinessSystemPerson(data);
      })
    );
  }
}
