<form [formGroup]="formGroup">
  <div class="o-fieldset__row mb-3">
    <label>Visa för företag/organisationer</label>
    <div class="o-fieldset__row mt-2">
      <label class="c-radio">
        <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="filterMode" [value]="FilterMode.All">
        <span class="c-radio__label">Alla</span>
      </label>
      <label class="c-radio ms-3">
        <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="filterMode" [value]="FilterMode.Selected">
        <span class="c-radio__label">Utvalda</span>
      </label>
    </div>

    @if(currentFilterMode == FilterMode.Selected){
    <div>
      <div class="c-input focus-within mt-2">
        <app-customer-search class="w-100"
                             formControlName="customer"
                             [clearSearchOnSelectedMatch]="true"
                             [(excludePartIds)]="excludePartIds">
        </app-customer-search>
      </div>
      <div class="mt-2">
        <div *ngFor="let selectedCustomer of selectedCustomers">
          <div>
            {{selectedCustomer.name}}&nbsp;{{selectedCustomer.organizationNumber}}
            <i class="c-icon--small c-icon c-icon--[bin] float-end show-cursor" title="Visa inte för företaget/organisationen" (click)="unselectCustomer(selectedCustomer.customerId)"></i>
          </div>
        </div>
        <div *ngIf="validationCustomerMessage">
          <small class="is-error w-100">{{validationCustomerMessage}}</small>
        </div>
      </div>
    </div>
    }
  </div>
</form>
