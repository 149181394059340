<div *ngIf="!isEditing" class="d-flex justify-content-between align-items-center">
    <div>                
        <span [class.line-through]="frameAgreement && !frameAgreement.enabled">{{ frameAgreement !== null ? frameAgreement.name + ' ('+ frameAgreement.quotaAccountName +')' : '-'}}</span>        
    </div>            
    <button *ngIf="!isEditing" type="button" class="c-btn c-btn--secondary ms-2 icon-button" (click)="onEdit()" title="Ändra">        
      <i class="c-icon c-icon--small c-icon--[pen]"></i> 
  </button>            
</div>
<div *ngIf="isEditing">
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <div class="d-flex justify-content-start">
        <app-frame-agreement-search-advanced class="w-100"
          formControlName="frameAgreement">
        </app-frame-agreement-search-advanced>
      </div>
      <small *ngIf="isError" class="is-error">Ramavtal måste anges</small>
      <div class="d-flex justify-content-end mt-2">
        <button type="button" class="c-btn c-btn--secondary me-2" (click)="onCancel()">
          <span class="c-btn__text">Avbryt</span>
        </button>       
        <button type="button" class="c-btn c-btn--primary" (click)="onSave()">
          <span class="c-btn__text">Spara</span>
        </button>       
      </div>
    </form>
</div>   
