// TODO Byt namn till CustomerFilterDetail och använd även i affiliate (AffiliateCustomerFilterDetail)
export class NewsArticleCustomerFilterDetail {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.affiliateId = jsonObject.affiliateId;
    this.customerId = jsonObject.customerId;
    this.partId = jsonObject.partId;
    this.name = jsonObject.name;
    this.organizationNumber = jsonObject.organizationNumber;
  }

  affiliateId: number;
  customerId: number;
  partId: number;
  name: string;
  organizationNumber: string;
}
