<div class="c-hero-teaser" [class.isFrontPageNews]="hasFrontPageNews === true">
  <img src="assets/images/bg-img-desktop-hd.jpg" class="c-hero-teaser__image">
</div>
<div class="c-hero-teaser__content">
  <div class="o-page-wrap">
    <div class="c-hero-teaser__box u-pb-none">
      <div class="o-layout o-layout--middle u-mh-none u-mb-small">
        <h3 class="text-start mt-2 mb-0">FleetWeb</h3>
        <div class="o-layout__item u-2/4 u-ph-none mt-0">
          <img class="c-media-grid__image mt-3 mb-3" src="assets/images/fleet-support.svg">
        </div>
      </div>
     
      <ng-template [ngIf]="showFrontPageNews">
        <div>
          <ng-container *ngFor="let item of frontPageNews">
            <app-news-card class="c-card mb-2" [singleNews]="item" [class.isImportant]="item.important"></app-news-card>
          </ng-container>
        </div>
      </ng-template>

      <button type="button" class="c-btn mt-2 mb-4" (click)="signIn()" [disabled]="!canSignIn">
        <span class="c-btn__text">Logga in</span>
      </button>
      
    </div>
  </div>
</div>
