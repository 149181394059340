export class BusinessSystemConnectionInfo{
    constructor(jsonObject: any = null) {
        if (jsonObject == null) {
            return;
        }
    
        this.firstName = jsonObject.firstName;
        this.lastName = jsonObject.lastName;
        this.email = jsonObject.email;
        this.socialSecurityNumber = jsonObject.socialSecurityNumber;
        this.customerId = jsonObject.customerId;
        this.customerNumber = jsonObject.customerNumber;
    }

    firstName: string;
    lastName: string;
    socialSecurityNumber: string;
    customerNumber: number;
    customerId: number;
    email: string;  
}