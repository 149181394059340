import { Component, Output, EventEmitter, Input } from "@angular/core";
import { PermissionGroups } from "@core/models/permission-groups";
import { AuthenticationService } from "@core/services/authentication.service";
import { RouteMetadata } from '@shared/route-metadata';


@Component({
  selector: "app-hamburger-menu",
  templateUrl: "./hamburger-menu.component.html",
  styleUrls: ["./hamburger-menu.component.scss"]
})
export class HamburgerMenuComponent {

  @Output() hideMenu = new EventEmitter<any>();
  @Input() active: boolean;

  routeMetadata = RouteMetadata;
  routeMetadataArray: any[] = [];
  secondLevelOpen: boolean[];
  counter: number = 0;

  constructor(private _authenticationService: AuthenticationService) {
    this.secondLevelOpen = [];
  }

  keepOrder = (a, _) => {
    return a;
  }

  closeMenu() {
    this.secondLevelOpen.fill(false);
    this.hideMenu.emit();
  }

  toggleSecondLevelMenu(i: number) {
    this.secondLevelOpen.forEach((_, index) => {
      if ((index) != i) {
        this.secondLevelOpen[index] = false;
      }
    });
    this.secondLevelOpen[i] = !this.secondLevelOpen[i];
  }

  secondLevelIsOpen(): boolean {
    return this.secondLevelOpen.includes(true);
  }

  hasPermission(permissions: string[]): boolean {
    for (const permission of permissions) {
      if (this._authenticationService.hasPermissionStringInput(permission)) {
        return true;
      }
    }
    return false;
  }

  get hasFleetWebPermissions() {
    return this._authenticationService.hasAnyPermission(PermissionGroups.CompanyPermissions);
  }
}
