export class AuthwayModule {
    constructor(jsonObject: any = null) {
    
        if(jsonObject == null){
          return;
        }
        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.active = jsonObject.active;
    }

    id: string;
    name : string;
    active: boolean;
}