import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map, catchError, of } from 'rxjs';
import { UserModel } from '@core/models/user.model';
import { BaseApiService } from '@core/services/base-api.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import { Permission } from '@core/models/permissions.enum';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from '@domain/services/notification.service';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  public static LogoutUrl = "Authentication/Logout";

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser$: Observable<UserModel>;
  
  constructor(private _apiService: BaseApiService,
    private _router: Router,
    private _dialogService: DialogService,  
    private _sessionStorageService: SessionStorageService,
    private _localStorageService: LocalStorageService, 
    private _notificationService: NotificationService,
    private _loggingService: LoggingService) {

    this.currentUserSubject = new BehaviorSubject<UserModel>(_sessionStorageService.currentUser);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  getUser(): Observable<UserModel> {
    if(this.currentUserSubject.value === null) {
        return this._apiService.get("Authentication/GetUser").pipe(
          map(data => {
            let user: UserModel = data ? new UserModel(data) : null;
            this.updateCurrentUser(user);
            return user;
          }),
          catchError((err) => {
            this._loggingService.logError("An error occurred while fetching user data from the server.", err);
            this.updateCurrentUser(null);
            return of(null as UserModel); 
          })
        );
    }

    return new Observable(r => { return r.next(this.currentUserSubject.value); });
  }

  signIn(): void {
    var returnUrl = this._localStorageService.returnUrl ?? "/";    
    window.location.href = `${window.location.origin}/bff/signin?returnUrl=${returnUrl}`;
  }

  signOut(): void {    
    this.clearCurrentUser();
    var returnUrl = "/";    
    window.location.href = `${window.location.origin}/bff/signout?returnUrl=${returnUrl}`;    
  }
 
  impersonate(sub: string): void {
    this.clearCurrentUser();
    window.location.href = `${window.location.origin}/bff/Authentication/Impersonate?sub=${sub}`;    
  }

  revertImpersonation(){
    this.clearCurrentUser();    
    window.location.href = `${window.location.origin}/bff/Authentication/RevertImpersonation`;     
  }

  signSuborder(suborderId: number){   
    window.location.href = `${window.location.origin}/bff/Authentication/Sign?suborderId=${suborderId}`;     
  }
  
  handleUnAuthorizedUser(){
    // we force re-login when receiving status 401 Unauthorized

    this._loggingService.logInformation('Unauthorized user, clearing browser user info and navigate to login.');
    this._localStorageService.returnUrl = this._router.url;
    // if the same view makes multiple requests, we only want to clear the browser user info, 
    // display the error dialog and redirect to login page for the first request that gets
    // the 401 response. 
    
    if(this.currentUserValue !== null){
      this.updateCurrentUser(null);        
      this._sessionStorageService.clearAll();
      var modalRef = this._dialogService.showErrorMessage("Utloggad", "Du har blivit utloggad från FleetWeb.");
      modalRef.result.then(_ => {
          this._router.navigate(['/login']);               
      });
    }
  }

  clearCurrentUser(){
    this.updateCurrentUser(null);
    this._sessionStorageService.clearAll();
  }

  refreshCurrentUser() {
    this.clearCurrentUser();
    this.getUser().subscribe();
  }

  hasPermissionStringInput(permission: string): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermissionStringInput(permission);
  }

  hasPermission(permission: Permission): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermission(permission);
  }

  hasAnyPermission(permissions: Array<Permission>): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasAnyPermission(permissions);
  }

  hasAllPermissions(permissions: Array<Permission>): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasAllPermissions(permissions);
  }

  get hasPermissions(): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermissions;
  }

  get hasReportPermissions(): boolean {
    if (!this.currentUserValue) {
      return false;
    }
    return this.currentUserValue.hasReportPermissions;
  }

  private updateCurrentUser(user: UserModel) {
    this._sessionStorageService.currentUser = user;
    this.currentUserSubject.next(user);

    if (user && user.hasPermission(Permission.LäsaPåminnelser)) {
      this._notificationService.updateNotifications();
    }
    else {
      this._notificationService.resetNotifications();
    }
  }
}

