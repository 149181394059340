<div id="modal-5" class="c-modal" tabindex="-1" role="dialog">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__wrapper">
        <div class="c-modal__header">
            <button type="button" class="c-modal__close-btn close-5-modal" aria-label="Close" (click)="modal.close(false)"></button>
        </div>
      <div class="c-modal__content">
        <header>
          <h3 class="text-start">Exportera användare</h3>
        </header>
        <p class="text-start">
          Välj typ av export:
        </p>
        <form [formGroup]="form" class="w-100">
          <div class="row">
            <div class="col">
              <fieldset class="o-fieldset u-mb">
                <div class="o-fieldset__row text-start mb-2">
                  <label class="c-radio">
                    <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="exportType" [value]="ExportType.Basic">
                    <span class="c-radio__label">Grundläggande</span>
                  </label>       
                </div>           
                <div class="o-fieldset__row text-start mb-2">
                  <label class="c-radio">
                    <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="exportType" [value]="ExportType.Customers">
                    <span class="c-radio__label">Behörighet till kunder</span>
                  </label>  
                </div>           
                <div class="o-fieldset__row text-start">
                  <label class="c-radio">
                    <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="exportType" [value]="ExportType.CostCenters">
                    <span class="c-radio__label">Behörighet till kostnadsställen</span>
                  </label>                  
                </div>
              </fieldset>
            </div>
          </div>
        </form> 
        <div class="alert alert-info text-start mb-3">          
          <p *ngIf="selectedExportType === ExportType.Basic">
            Grundläggande information om användarna och deras behörigheter till FleetWeb. 
          </p>
          <p *ngIf="selectedExportType === ExportType.Customers">
            Information om användarna, inklusive deras eventuella ramavtalsbehörigheter till kunder.
          </p>
          <p *ngIf="selectedExportType === ExportType.CostCenters">
            Fullständig information om användarna, inklusive deras eventuella ramavtalsbehörigheter till kunder och kostnadsställen.
          </p>
        </div>         
        <small *ngIf="errorMessage" class="is-error text-start mb-3">{{errorMessage}}</small>
      </div>
      <div class="c-modal__footer d-flex justify-content-between">
        <button type="button" class="c-btn c-btn--secondary" (click)="modal.close(false)">
          <span class="c-btn__text">Avbryt</span>
        </button>
        <button type="button" class="c-btn close-5-btn-modal" aria-label="Close" (click)="onExport()">
          <span class="c-btn__text">Ok</span>
          <div *ngIf="isExporting" class="button-spinner-wrapper spinner-wrapper ms-2">
            <app-spinner [style]="'small'"></app-spinner>
          </div>
        </button>
      </div>
    </div>
</div>
