<ng-template [ngIf]="!isMainLoadingUser" [ngIfElse]="loading">
  <ng-template [ngIf]="user" [ngIfElse]="noUser">
    
    <ng-template [ngIf]="!user.deletedInAuthway" [ngIfElse]="userDeleted">
      <section  class="o-page-wrap o-page-wrap--xsmall mt-5 mb-5">
        <div class="c-card p-4">    
          <div class="d-flex justify-content-between">
            <h3 class="u-mb-xsmall">{{canAdministerUsers ? "Redigera" : "Visa"}}</h3>
            <button *ngIf="canImpersonate" [disabled]="isImpersonating" type="button" title="Kör som..." class="c-btn c-btn--secondary" (click)="onImpersonate()">
              <i  class="c-icon c-icon--small c-icon--[user] me-2"></i> Kör som...         
            </button>
          </div>      
          <app-authway-user-editor [user]="user" (newValue)="onNewUserValue($event)"></app-authway-user-editor>
          <app-business-system-person-info *ngIf="canAdministerAllUsers" [user]="user" (newValue)="onNewBusinessSystemPerson($event)"></app-business-system-person-info>
          <div class="mb-2 mt-5">
            <h5>Behörighet till FleetWeb*</h5>
            <ng-container *ngIf="tenantMissingGroups;else secondIf">
              <div class="alert alert-danger">
                Organisationen {{user.tenantName}} saknar grupper i Authway. Administrera organisationen och kontrollera att FleetWeb-modulen är aktiverad.
              </div>       
            </ng-container>            
            <ng-template #secondIf>
                <ng-container *ngIf="!hasInternalGroups && !businessSystemPerson;else thirdIf">
                  <div *ngIf="canAdministerAllUsers" class="alert alert-info">
                    Koppla användaren till en person i affärssystemet för att tilldela behörighet till FleetWeb.
                  </div>
                  <div *ngIf="!canAdministerAllUsers" class="alert alert-info">
                    <span *ngIf="user.lastLoginDate">
                      Denna användare saknar koppling till person i vagnparken och kommer inte kunna ta del av sitt engagemang. Vänligen kontakta Fleet Support.
                    </span>
                    <span *ngIf="!user.lastLoginDate">
                      Denna användare saknar för närvarande koppling till person i vagnparken. När användaren loggar in i systemet för första gången kommer ett försök till automatisk koppling att ske.
                    </span>
                  </div>            
                </ng-container>
            </ng-template>
            <ng-template #thirdIf>
              <div class="mt-2">
                <form *ngIf="formGroup" [formGroup]="formGroup">
                  <div formArrayName="groups" *ngFor="let check of groupsFormArray.controls; let i = index" class="switch mb-2 mt-2">
                    <input type="checkbox" class="switch" [id]="groups[i].id" [formControl]="check"/>
                    <label [for]="groups[i].id" class="m-0">{{ groups[i].name }}</label>
                    <button *ngIf="groups[i].external" title="Extern grupp" type="button" class="btn c-btn--secondary btn-circle float-end" ngbPopover="Gruppen går inte att lägga till eller ta bort då den är extern och tilldelas automatiskt av kundens egen organisation." popoverTitle="Extern grupp">i</button>        
                    <button *ngIf="groups[i].requiresSignature" title="Kräver signering" type="button" class="btn c-btn--secondary btn-circle float-end" ngbPopover="Gruppen går inte att lägga till eller ta bort då den kräver signering av firmatecknare. Kontakta Fleet Support för att begära tillägg till ramavtalet." popoverTitle="Kräver signering">i</button>
                  </div>
                  <small *ngIf="groupsInvalid" class="is-error">
                    Minst en grupp måste väljas.
                  </small>
                </form>
              </div>            
            </ng-template>
          </div>
          <div class="mt-5">
            <h5>Ramavtalsbehörigheter</h5>
            <ng-container *ngIf="!frameAgreementId;else second">
              <div class="alert alert-danger">              
                Organisationen {{user.tenantName}} saknar koppling till ramavtal i Authway. Administrera organisationen och koppla till korrekt ramavtal.
              </div>
            </ng-container>            
            <ng-template #second>
                <ng-container *ngIf="!businessSystemPerson;else third">
                  <div *ngIf="canAdministerAllUsers" class="alert alert-info">
                    Koppla användaren till en person i affärssystemet för att tilldela ramavtalsbehörigheter.
                  </div>
                  <div *ngIf="!canAdministerAllUsers" class="alert alert-info">
                    <span *ngIf="user.lastLoginDate">
                      Denna användare saknar koppling till person i vagnparken och kommer inte kunna ta del av sitt engagemang. Vänligen kontakta Fleet Support.
                    </span>
                    <span *ngIf="!user.lastLoginDate">
                      Denna användare saknar för närvarande koppling till person i vagnparken. När användaren loggar in i systemet för första gången kommer ett försök till automatisk koppling att ske.
                    </span>
                  </div>
                </ng-container>
            </ng-template>
            <ng-template #third>
              <ng-container *ngIf="!hasElevatedGroup;else fourth">
                <div class="alert alert-info">
                  Tilldela användaren en högre behörighetsgrupp för att tilldela ramavtalsbehörigheter.
                </div>
              </ng-container>
            </ng-template>
            <ng-template #fourth>
              <div>
                <label>Behörig till kunder*</label>
                <app-customers-selector [user]="user"></app-customers-selector>            
              </div>
            </ng-template>
          </div>
          <div class="mt-4">
            <label>
              <em class="vertical-align-middle">* Obligatoriska uppgifter</em>
            </label>
          </div>        
        </div>
      </section>
    </ng-template>
  </ng-template>
</ng-template>
<ng-template #loading>
  <div class="c-card spinner-wrapper">
    <app-spinner [style]="'section'"></app-spinner>
  </div>  
</ng-template>
<ng-template #noUser>
  <section class="o-page-wrap o-page-wrap--xsmall mt-5 mb-5">
    <div class="alert alert-danger">
      <span>Användaren kunde inte hämtas.</span>      
    </div>
  </section>
</ng-template>
<ng-template #userDeleted>
  <section class="o-page-wrap o-page-wrap--xsmall mt-5 mb-5">
    <div class="alert alert-danger">
      <span>Användaren kunde inte hittas i Authway. Du kan välja att ta bort användaren även från FleetWeb. </span>
      <div class="d-flex justify-content-end mt-2">
        <button type="button" title="Ta bort" class="c-btn c-btn--secondary float-end" (click)="onDeleteUser()">
          Ta bort         
        </button>
      </div>
    </div>
  </section>
</ng-template>

