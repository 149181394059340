export class AuthwayUserGroup {
    constructor(jsonObject: any = null) {
      
      if(jsonObject == null){
        return;
      }
  
      this.id = jsonObject.id;
      this.name = jsonObject.name;
      this.internal = jsonObject.internal;
      this.elevated = jsonObject.elevated;
      this.external = jsonObject.external;
      this.requiresSignature = jsonObject.requiresSignature;
    }
  
    id: string;
    name: string;
    internal: boolean;
    elevated: boolean;
    external: boolean;
    requiresSignature: boolean;
  }
