import { Component } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SuborderService } from "@domain/services/suborder.service";
import { SearchSellerQuery } from "@domain/models/filters/search-seller-query.model";
import { SellerPart } from "@domain/models/seller-part.model";
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";
import { QueryResult } from "@domain/models/query-result.model";

@Component({
  standalone: true,
  selector: "app-seller-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SellerSearchComponent
    }
  ]
})
export class SellerSearchComponent extends TypeAheadSearchComponent<SellerPart> {
      
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 15;

  constructor(
    private suborderService: SuborderService,
    parentFormGroup: FormGroupDirective) {
      super(parentFormGroup);
  }

  public override get placeholderText(): string {
    return "Sök säljare";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: SellerPart): string {
    if (!match || match == null) {
      return "";
    }
    
    return `${match.name ? match.name.trimEnd() : ""}  ${match.email ? match.email.trimEnd() : ""} ${match.phone ? match.phone.trimEnd() : ""}`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade säljare.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på säljarens namn med minst ${minSearchTermLength} bokstäver.`;
  }
  
  protected override searchAction(term: string): Observable<QueryResult<SellerPart>> {
    const searchSellerQuery = new SearchSellerQuery();
    searchSellerQuery.searchText = term.toString();
    searchSellerQuery.pageSize = this._maxNumberOfResults;

    return this.suborderService.searchSellers(searchSellerQuery);
  }    

  protected override isInstanceOfType(value: SellerPart): boolean {
    return value instanceof SellerPart;
  }

  protected override strikeThroughText(_: SellerPart): boolean {
    return false;
  }
}
