import { OrganizationTargetGroup } from "@core/models/organization-target-group.enum";
import { NewsArticleCustomerFilterDetail } from "@domain/models/news";

export class GetNewsArticleResponse {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.id = jsonObject.id;
    this.title = jsonObject.title;
    this.content = jsonObject.content;
    this.startDateString = jsonObject.startDateString;
    this.endDateString = jsonObject.endDateString;
    this.frontPage = jsonObject.frontPage;
    this.important = jsonObject.important;
    this.showToCustomerIds = jsonObject.showToCustomerIds;
    this.active = jsonObject.active;
    this.hasBeenPublished = jsonObject.hasBeenPublished;
    this.roles = jsonObject.roles;
    this.customers = jsonObject.customers ? jsonObject.customers.map(customer => new NewsArticleCustomerFilterDetail(customer)) : new Array<NewsArticleCustomerFilterDetail>();

    this.createdAtUtc = new Date(jsonObject.createdAtUtc);
    this.createdBy = jsonObject.createdBy;
    this.updatedAtUtc = jsonObject.updatedAtUtc ? new Date(jsonObject.updatedAtUtc) : null;
    this.updatedBy = jsonObject.updatedBy;
  }

  id: number;
  title: string;
  content: string;
  startDateString: string;
  endDateString?: string;
  frontPage: boolean;
  important: boolean;
  showToCustomerIds: number[];
  active: boolean;
  hasBeenPublished: boolean;
  roles: OrganizationTargetGroup[];
  customers: NewsArticleCustomerFilterDetail[];

  createdAtUtc?: Date;
  createdBy?: string;
  updatedAtUtc?: Date;
  updatedBy?: string;

  get showToAllCustomers(): boolean {
    return this.customers.length === 0;
  }

  get noEndDate(): boolean {
    return this.endDateString == null;
  }
}
