import { FrameAgreement } from "../frame-agreement.model";
import { AuthwayModule } from "./authway-module.model";
import { EntraSettings } from "./entra-settings.model";
import { FleetWebOrganizationInfo } from "./fleetweb-organization-info.model";

export class FleetWebOrganizationDetails extends FleetWebOrganizationInfo{
  constructor(jsonObject: any = null) {        
    super(jsonObject);
    
    if(jsonObject == null){
      return;
    }

    this.frameAgreement = jsonObject.frameAgreement ? new FrameAgreement(jsonObject.frameAgreement) : null;
    this.connectedFrameAgreement = jsonObject.connectedFrameAgreement ? new FrameAgreement(jsonObject.connectedFrameAgreement) : null;
    this.entraSettings = jsonObject.entraSettings ? new EntraSettings(jsonObject.entraSettings) : null;
    this.modules = jsonObject.modules ? jsonObject.modules.map(m => new AuthwayModule(m)) : new Array<AuthwayModule>();
  }

  frameAgreement: FrameAgreement;
  connectedFrameAgreement: FrameAgreement;
  modules: Array<AuthwayModule> = new Array<AuthwayModule>();
  entraSettings: EntraSettings;
}
