import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError, map, of } from 'rxjs';
import { LoggingService } from './logging.service';
import { ClientError } from '@core/models/client-error.model';
import { ServerError } from '@core/models/server-error.model';
import { CommunicationError } from '@core/models/communication-error.model';
import { ValidationErrorResponse } from '@core/models/validation-error-response.model';

@Injectable()
export class BaseApiService {
  params = new HttpParams();

  constructor(private _httpService: HttpClient,
    private _loggingService: LoggingService
  ) { }

  get(url: string): Observable<any> {    
    return this._httpService
      .get(environment.apiBaseUrl + '/' + url, { observe: 'response', params: this.params })
      .pipe(map(res => res.body), catchError(error => this.handleError(error)));
  }

  post(url: string, model: any): Observable<any> {    
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers: headers, params: this.params };
    const body = JSON.stringify(model);
    return this._httpService
      .post(environment.apiBaseUrl + '/' + url, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  download(url: string, model: any = null): Observable<Blob> {    
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers: headers, responseType: 'blob' as 'json', params: this.params };
    const body = model === null ? null : JSON.stringify(model);
    return this._httpService
      .post(environment.apiBaseUrl + '/' + url, body, options)
      .pipe(catchError(error => this.handleError(error)), map(data => new Blob([data as any], { type: (data as any).type })));
  }

  put(url: string, model: any = null): Observable<any> {    
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers: headers, params: this.params };
    const body = model === null ? null : JSON.stringify(model);
    return this._httpService
      .put(environment.apiBaseUrl + '/' + url, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  delete(url: string, model: any = null): Observable<any> {    
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    var options;

    if(model){
      options =  { headers: headers, params: this.params, body: JSON.stringify(model)};
    }
    else{
      options = { headers: headers, params: this.params };
    }

    return this._httpService
      .delete(environment.apiBaseUrl + '/' + url, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  upload(url: string, file: File): Observable<any> {    
    const options = { params: this.params };
    const formData = new FormData();
    formData.append("File", file, file.name);

    return this._httpService
      .post(environment.apiBaseUrl + "/" + url, formData, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  putFile(url: string, form: FormData): Observable<any> {    
    const options = { params: this.params };
    return this._httpService
      .put(environment.apiBaseUrl + "/" + url, form, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  postFile(url: string, form: FormData): Observable<any> {    
    const options = { params: this.params };
    return this._httpService
      .post(environment.apiBaseUrl + "/" + url, form, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  private handleError(errorResponse: HttpErrorResponse) {     
    
    if(errorResponse.status === 0) {
      this._loggingService.logError("A communication error occurred when attempting to call the API", errorResponse);
      return throwError(() => new CommunicationError());
    }

    if (errorResponse.status === 422) {
      this._loggingService.logInformation(`Server side validation failed, status ${errorResponse.status}`, errorResponse);
      return of(new ValidationErrorResponse(errorResponse.error));
    }

    if (errorResponse.status >= 400 && errorResponse.status <= 499) {
      this._loggingService.logWarning(`The server responded with a ${errorResponse.status} client error response`, errorResponse);
      return throwError(() => new ClientError(errorResponse.error, errorResponse.status));
    }
    
    this._loggingService.logError(`The server responded with a ${errorResponse.status} server error response`, errorResponse);
    return throwError(() => new ServerError(errorResponse.status));
  } 
}
