export class AuthwayOrganizationInfo{
    constructor(jsonObject: any = null) {
    
        if(jsonObject == null){
          return;
        }
    
        this.name = jsonObject.name;
        this.organizationNumber = jsonObject.organizationNumber;
        this.tenantId = jsonObject.tenantId;        
      }

    name: string;
    organizationNumber: string;
    tenantId: string;  
}