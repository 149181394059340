import { FrameAgreement } from "./frame-agreement.model";

export class BusinessSystemPerson  {
  
  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.address = jsonObject.address;
    this.zip = jsonObject.zip;
    this.city = jsonObject.city;
    this.phone = jsonObject.phone;
    this.mobilePhone = jsonObject.mobilePhone;
    this.email = jsonObject.email;
    this.employeeId = jsonObject.employeeId;
    this.roles = jsonObject.rolesString;
    this.socialSecurityNumber = jsonObject.socialSecurityNumber;
    this.customerNumber = jsonObject.customerNumber;
    this.customerId = jsonObject.customerId;
    this.quotaAccountId = jsonObject.quotaAccountId;
    this.frameAgreement = jsonObject.frameAgreement ? new FrameAgreement(jsonObject.frameAgreement) : null;
    this.multipleFrameAgreementCommitment = jsonObject.multipleFrameAgreementCommitment;
  }

  firstName: string;
  lastName: string;
  address: string;
  address1: string;
  zip: string;
  city: string;
  phone: string;
  mobilePhone: string;
  email: string;
  employeeId: string;
  roles: string;
  socialSecurityNumber: string;
  customerNumber: number;
  customerId: number;
  quotaAccountId: number;
  frameAgreement: FrameAgreement;
  multipleFrameAgreementCommitment: boolean;

}
