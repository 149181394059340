<div class="c-modal">
  <div class="c-modal__overlay"></div>
  <div class="c-modal__wrapper maxwidth">
    <div class="c-modal__header">
      <button type="button" class="c-modal__close-btn close-modal" aria-label="Close"
              (click)="modal.close(false)"></button>
    </div>
    <div class="c-modal__content">
      <header *ngIf="title">
        <h3 class="text-start">{{ title }}</h3>
      </header>
      <p class="text-start">
        <span [innerHTML]="message"></span>
      </p>
    </div>
    @if (cancelButtonText) {
      <div class="c-modal__footer d-flex justify-content-between">
        <button type="button" class="c-btn close-btn-modal" aria-label="Close" (click)="modal.close(true)">
          {{ buttonText }}
        </button>
        <button type="button" class="c-btn c-btn--secondary" (click)="modal.close(false)">
          {{ cancelButtonText }}
        </button>
      </div>
    }@else {
      <div class="c-modal__footer">
        <button type="button" class="c-btn close-btn-modal" aria-label="Close" (click)="modal.close(true)">
          {{ buttonText }}
        </button>
      </div>
    }
  </div>
</div>
