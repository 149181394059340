 import { Injectable } from '@angular/core';
 import { BaseApiService } from '../../core/services/base-api.service';
 import { Observable, BehaviorSubject} from 'rxjs';
 import { NotificationGroup } from '@domain/models/notification-group.model';

 @Injectable({
     providedIn: 'root'
 })
 export class NotificationService {

     private notificationsSubject: BehaviorSubject<Array<NotificationGroup>>;
     public notifications$: Observable<Array<NotificationGroup>>;

     private countSubject: BehaviorSubject<number>;
     public count$: Observable<number>;

     constructor(private _apiService: BaseApiService) {
         this.notificationsSubject = new BehaviorSubject<Array<NotificationGroup>>(null);
         this.notifications$  = this.notificationsSubject.asObservable();
         this.countSubject = new BehaviorSubject<number>(0);
         this.count$ = this.countSubject.asObservable();
     }

     get notifications() : Array<NotificationGroup>{
         return this.notificationsSubject.getValue();
     }

     get count() : number{
         return this.countSubject.getValue();
     }
    
     updateNotifications() {
         this._apiService.get("Notification").subscribe(
             data => {            
                 const result = data.map(n => { return new NotificationGroup(n); });
                 this.notificationsSubject.next(result);

                 const count = result.reduce((sum, current) => sum + current.notifications.length, 0);    
                 this.countSubject.next(count);
            }
         );
     }

     resetNotifications(){
         this.notificationsSubject.next(null);
         this.countSubject.next(0);
     }
 }
