<div *ngIf="!style || style === 'page'" class="c-spinner__full-page-wrapper">
    <div class="c-spinner">
        <svg>
            <circle class="c-spinner__indicator" fill="none"></circle>
        </svg>
    </div>
</div>

<div *ngIf="style === 'section'" class="c-section-spinner">    
</div>

<div *ngIf="style === 'small'" class="c-section-spinner c-section-spinner--small">    
</div>

<div *ngIf="style === 'wrapper'" class="spinner-wrapper">
    <div class="c-section-spinner"></div>
</div>  