import { Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from "rxjs";
import { Dealer } from "@domain/models/dealer.model";
import { SearchDealerQuery } from "@domain/models/filters/search-dealer-query.model";
import { DealerService } from "@domain/services/dealer.service";
import { QueryResult } from "@domain/models/query-result.model";
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";

@Component({
  standalone: true,
  selector: "app-dealer-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DealerSearchComponent
    }
  ]
})
export class DealerSearchComponent extends TypeAheadSearchComponent<Dealer> {
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 15;

  @Input() intitialDealer: Dealer;

  constructor(private dealerService: DealerService,
    parentFormGroup: FormGroupDirective) {
    super(parentFormGroup);
  }

  public override get placeholderText(): string {
    return "Sök leverantör";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: Dealer): string {
    if (!match || match == null) {
      return "";
    }

    return `${match.name ? match.name.trimEnd() : ""}`;
  }


  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade leverantörer.`;
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på leverantörens namn med ${minSearchTermLength} bokstäver.`;
  }

  protected override searchAction(term: string): Observable<QueryResult<Dealer>> {
    const searchDealerQuery = new SearchDealerQuery();
    searchDealerQuery.searchText = term.toString();
    searchDealerQuery.pageSize = this._maxNumberOfResults;

    return this.dealerService.getDealers(searchDealerQuery);
  }

  protected override isInstanceOfType(value: Dealer): boolean {
    return value instanceof Dealer;
  }

  protected override strikeThroughText(_: Dealer): boolean {
    return false;
  }
}
