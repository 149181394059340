import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ValidationErrorResponse } from "@core/models/validation-error-response.model";
import { DialogService } from "@core/services/dialog.service";
import { BusinessSystemPerson } from "@domain/models/business-system-person.model";
import { BusinessSystemConnectionInfo } from "@domain/models/user/business-system-connection-info.model";
import { ConnectFleetWebUserRequest } from "@domain/models/user/connect-fleetweb-user-request.model";
import { DisconnectFleetWebUserRequest } from "@domain/models/user/disconnect-fleetweb-user-request.model";
import { FleetWebUserDetails } from "@domain/models/user/fleetweb-user-details.model";
import { UserService } from "@domain/services/user.service";
import { LoadingHelper } from "@shared/helpers/loading.helper";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-business-system-person-info',
  templateUrl: './business-system-person-info.component.html',
  styleUrls: ['./business-system-person-info.component.scss']
})
export class BusinessSystemPersonInfoComponent implements OnInit, OnDestroy {

  @Input() user: FleetWebUserDetails = null;
  @Output() newValue: EventEmitter<BusinessSystemPerson> = new EventEmitter();
  formGroup: FormGroup;
  personFormControl: FormControl<BusinessSystemConnectionInfo>;

  private _loadingHelper = new LoadingHelper();
//   private _validationLoadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();
  private _currentBusinessSystemPerson: BusinessSystemPerson;

  constructor(private _dialogService: DialogService,
    private _userService: UserService) {
  }

  ngOnInit(): void {
    this.initForm();
    this._currentBusinessSystemPerson = this.originalBusinessSystemPerson;
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  get isLoading() {
    return this._loadingHelper.isLoading;
  }

//   get isValidating() {
//     return this._validationLoadingHelper.isLoading;
//   }

  get currentBusinessSystemPerson(): BusinessSystemPerson {
    return this._currentBusinessSystemPerson;
  }

  get originalBusinessSystemPerson(): BusinessSystemPerson {
    return this.user.businessSystemPerson;
  }

  get isEditing() {
    return !this.formGroup.disabled;
  }

  get canConnect() {
    return this._currentBusinessSystemPerson != null &&
      (this.originalBusinessSystemPerson == null || this.originalBusinessSystemPerson.customerId !== this._currentBusinessSystemPerson.customerId)
  }

  get canSendInvitation(){
    return this.user.canSendInvitationLink;
  }

  private initForm() {
    this.personFormControl = new FormControl<BusinessSystemConnectionInfo>(null);

    this.formGroup = new FormGroup({
      person: this.personFormControl
    });

    this.formGroup.disable();

    this._componentSubscriptions.push(this.personFormControl.valueChanges.subscribe(value => {
      if (value) {
        this.validateConnection(value);
      }
    }));
  }

  onEdit() {
    this.formGroup.enable();
  }

  onCancel() {
    this._currentBusinessSystemPerson = this.originalBusinessSystemPerson;
    this.formGroup.disable();
  }

  onConnect() {
    this.connectUser();
    this.formGroup.disable();
  }

  onDisconnect() {
    const modalRef = this._dialogService.showConfirm(
      "Koppla från användare",
      "Är du säker på att du vill koppla från användaren från personen i affärssystemet?",
      "Koppla från",
      "Avbryt");

    modalRef.result.then(result => {
      if (result === true)
        this.disconnectUser();
      this.formGroup.disable();
    });
  }

  private validateConnection(info: BusinessSystemConnectionInfo) {
    this._loadingHelper.startLoading();

    var customerId = info.customerId;

    this._userService.validateConnect(customerId).subscribe({
      next: (response) => {
        if (response instanceof ValidationErrorResponse) {
          this._dialogService.showErrorMessage(response.error.title, response.error.detail);
        }
        else {
          this._currentBusinessSystemPerson = response;
        }
      },
      error: (error) => {
        this._dialogService.showError(error, "Kontrollera koppling till person i affärssystemet");
        this._loadingHelper.stopLoading();
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private connectUser() {
    this._loadingHelper.startLoading();

    var connectRequest = new ConnectFleetWebUserRequest();
    connectRequest.userId = this.user.userId;
    connectRequest.tenantId = this.user.organization.tenantId;
    connectRequest.customerId = this._currentBusinessSystemPerson.customerId;

    this._userService.connectUser(connectRequest).subscribe({
      next: (response) => {
        if (response instanceof ValidationErrorResponse) {
          this._dialogService.showErrorMessage(response.error.title, response.error.detail);
          this.onCancel();
        }
        else {
          this.user.businessSystemPerson = response.businessSystemPerson;
          this.newValue.emit(response.businessSystemPerson);
        }
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Koppla användare till person i affärssystemet");

        this.onCancel();
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private disconnectUser() {
    this._loadingHelper.startLoading();

    var request = new DisconnectFleetWebUserRequest();
    request.userId = this.user.userId;
    request.tenantId = this.user.organization.tenantId;

    this._userService.disconnectUser(request).subscribe({
      next: () => {
        this.user.businessSystemPerson = null;
        this._currentBusinessSystemPerson = null;
        this.newValue.emit(null);
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Koppla från", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
}
