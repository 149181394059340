import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DialogService } from '@core/services/dialog.service';
import { Organization } from '@domain/models/organization.model';
import { FrameAgreementService } from '@domain/services/frame-agreement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '@shared/validators/custom-validators';
import { map, merge, Subscription } from 'rxjs';
import { EditUserOrganizationCostcentersComponent } from '../edit-user-organization-costcenters/edit-user-organization-costcenters.component';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { UpdateFleetWebUserOrganizationsRequest } from '@domain/models/user/update-fleetweb-user-organizations-request.model';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { UserService } from '@domain/services/user.service';
import { UserOrganizationInfo } from '@domain/models/user/user-organization-info.model';
import { UserCostCenterLimitationInfo } from '@domain/models/user/user-costcenter-limitation-info.model';
import { AuthenticationService } from '@core/services/authentication.service';
import { Permission } from '@core/models/permissions.enum';

@Component({
  selector: 'app-customers-selector',
  templateUrl: './customers-selector.component.html',
  styleUrls: ['./customers-selector.component.scss']
})
export class CustomersSelectorComponent implements OnInit, OnDestroy {
  @Input() user: FleetWebUserDetails = null;

  organizations = new Array<Organization>();

  formGroup: FormGroup;
  filterFormControl: FormControl<string>;
  customersFormArray: FormArray<FormControl<boolean>>;

  private _selectedOrganizations: Array<UserOrganizationInfo>;
  private _componentSubscriptions = new Array<Subscription>();
  private _loadingHelper = new LoadingHelper();

  constructor(private _frameAgreementService: FrameAgreementService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _modalService: NgbModal) {
  }

  ngOnInit() {
    this._selectedOrganizations = this.user.userOrganizationInfo.map(orgInfo => {
      var selectedOrganization = new UserOrganizationInfo();
      selectedOrganization.inSufficientCostCenterPrivileges = orgInfo.inSufficientCostCenterPrivileges;
      selectedOrganization.customerId = orgInfo.customerId;
      selectedOrganization.userCostCenterLimitationInfo = orgInfo.userCostCenterLimitationInfo.map(cc => {
        var selectedCostCenter = new UserCostCenterLimitationInfo();
        selectedCostCenter.id = cc.id;
        selectedCostCenter.text = cc.text;
        return selectedCostCenter;
      });
      return selectedOrganization;
    });

    this.initForm();
    this.getOrganizations();
  }

  ngOnDestroy(): void {    
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  private initForm() {
    this.filterFormControl = new FormControl<string>("");

    this._componentSubscriptions.push(this.filterFormControl.valueChanges.subscribe(() => {
      this.patchForm();
    }));

    this.customersFormArray = new FormArray<FormControl<boolean>>([], { validators: [CustomValidators.atLeastOneSelectedCheckbox] });
    this.formGroup = new FormGroup({
      customers: this.customersFormArray,
      filter: this.filterFormControl
    });
  }

  get isLoading(){
    return this._loadingHelper.isLoading;
  }

  get showFilter(): boolean{
    return this.organizations.length > 10;
  }

  get showSelectAll(): boolean {
    return this.canAdministerUsers && this.organizations.length > 0 && this.filteredOrganizations.length > 1;
  }

  get selectAllDisabled(): boolean {
    return this.showSelectAll && 
      this.filteredOrganizations.every(o => this._selectedOrganizations.find(s => s.customerId === o.customerId) != null);
  }

  get deselectAllDisabled(): boolean {
    return this.showSelectAll && 
      this.filteredOrganizations.every(o => this._selectedOrganizations.every(s => s.customerId !== o.customerId));
  }

  get filteredOrganizations(): Array<Organization> {
    if(this.filterFormControl.value){
      var searchString = this.filterFormControl.value;
      return this.organizations.filter(organization => {
        var isSelected = this._selectedOrganizations.filter(selectedOrganization => selectedOrganization.customerId === organization.customerId).length > 0;
        return isSelected || organization.name.toLowerCase().search(searchString.toLowerCase()) !== -1;
      });
    }
    return this.organizations;
  }

  get isFiltering(): boolean{
    return this.filterFormControl.value != null && this.filterFormControl.value !== "";
  }
  
  public get canAdministerUsers(): boolean{
    return this._authenticationService.hasAnyPermission([Permission.AdministreraAllaAnvändare, Permission.AdministreraFöretagsanvändare]);
  }

  isSelected(customerId : number){
    return this._selectedOrganizations.findIndex(o => o.customerId === customerId) !== -1;
  }

  isLimited(customerId : number){
    return this._selectedOrganizations.find(o => o.customerId === customerId).inSufficientCostCenterPrivileges;
  }

  onClearFilter(){
    this.filterFormControl.setValue(null);
  }
   
  private patchForm() {
    this.customersFormArray.clear();
    this.filteredOrganizations.forEach(organization => {
      var isSelected = this._selectedOrganizations.filter(selectedOrganization => selectedOrganization.customerId === organization.customerId).length > 0;
      var control = new FormControl<boolean>(isSelected);
      if(!this.canAdministerUsers){
        control.disable({emitEvent: false});
      }
      this.customersFormArray.push(control, {emitEvent: false});
    });
    this.subscribeToCustomerSelection();
  }

  private refreshForm(){
    this.customersFormArray.controls.forEach((formControl, index) => {
      var filteredOrganization = this.filteredOrganizations[index];
      var isSelected = this._selectedOrganizations.findIndex(selectedOrganization => selectedOrganization.customerId === filteredOrganization.customerId) !== -1;
      this.setValueWithoutEmitting(formControl, isSelected);
    });
  }

  private subscribeToCustomerSelection() {
    this._componentSubscriptions.push(merge(...this.customersFormArray.controls.map((control: FormControl<boolean>, index: number) =>
      control.valueChanges.pipe(map(value => {        
        return { control: control, value: value, customerId: this.filteredOrganizations[index].customerId };
    }))))
    .subscribe(change => {       
      if(change.value){
        this.addOrganizations([change.control], [change.customerId]);
       }
      else{
        this.removeOrganizations([change.control], [change.customerId]);
      }
    }));
  }

  onSelectAllCustomers() {
    this.customersFormArray.controls.forEach(control => {
      this.setValueWithoutEmitting(control, true);      
    });

    var organizationsToAdd = this.filteredOrganizations.filter(f => this._selectedOrganizations.findIndex(s => s.customerId === f.customerId) === -1).map(f => f.customerId);
    this.addOrganizations(this.customersFormArray.controls, organizationsToAdd);
   }

  onDeselectAllCustomers() {
    this.customersFormArray.controls.forEach(control => {
      this.setValueWithoutEmitting(control, false);      
    });

    var organizationToRemove = this.filteredOrganizations.filter(f => this._selectedOrganizations.findIndex(s => s.customerId === f.customerId) !== -1).map(f => f.customerId);
    this.removeOrganizations(this.customersFormArray.controls, organizationToRemove);
  }

  onEditCostCenters(organizationIndex: number) {    
    var organization = this.filteredOrganizations[organizationIndex];

    var modalRef = this._modalService.open(
      EditUserOrganizationCostcentersComponent,
      {
        backdrop: "static",
        //size: "sm", 
        animation: false,
        scrollable: true
      }
    );

    var selectedCustomer = this._selectedOrganizations.find(c => c.customerId === organization.customerId);

    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.organization = organization;
    modalRef.componentInstance.initialCostCenterIds = selectedCustomer.userCostCenterLimitationInfo.map(cl => cl.id);
    
    this._componentSubscriptions.push(modalRef.componentInstance.costCentersChanged.subscribe((result : UserOrganizationInfo) => {
      selectedCustomer.userCostCenterLimitationInfo = result.userCostCenterLimitationInfo.map(c => {
        var cc = new UserCostCenterLimitationInfo();
        cc.id = c.id;
        cc.text = c.text;
        return cc;
      });
    }));
  }

  getCostCenterLimitationCountText(organizationIndex: number) {
    var organization = this.filteredOrganizations[organizationIndex];
    var selectedCustomer = this._selectedOrganizations.find(c => c.customerId === organization.customerId);

    var selectedCostCentersCount = selectedCustomer.userCostCenterLimitationInfo.length;
    
    return selectedCostCentersCount === 0 ?
      `Alla av ${organization.costCenters.length} kostnadsställen` :
      `${selectedCostCentersCount} av ${organization.costCenters.length} kostnadsställen`;
  }

  getOrganizations() {
    this._loadingHelper.startLoading();
    this._frameAgreementService.getFrameAgreementDetails(this.user.frameAgreement.id).subscribe({
      next: data => {
        this.organizations = data.organizations;
        this.patchForm();

      },
      error: (error) => {
        this._dialogService.showError(error, "Hämta ramavtal", true);
        this._loadingHelper.stopLoading();
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private addOrganizations(formControls: Array<FormControl<boolean>>, customerIds: Array<number>) {    
    if(this._loadingHelper.isLoading){
      formControls.forEach(formControl => {
        this.setValueWithoutEmitting(formControl, false);
      });
      return;
    }

    this._loadingHelper.startLoading();

    var request = new UpdateFleetWebUserOrganizationsRequest();
    request.userId = this.user.userId;
    request.tenantId = this.user.organization.tenantId;
    request.organizationCustomerIds = customerIds;

    this._userService.addOrganizations(request).subscribe({
      next: () => {
        this._selectedOrganizations = this._selectedOrganizations.concat(customerIds.map(customerId => {
          var orgInfo = new UserOrganizationInfo();
              orgInfo.customerId = customerId;
          return orgInfo;
        }));
        
        if(request.userId === this._authenticationService.currentUserValue.sub){
          this._authenticationService.refreshCurrentUser();
        }
      },
      error: (error) => {
        this.refreshForm();
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Lägg till ramavtalsbehörighet till kund", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private removeOrganizations(formControls: Array<FormControl<boolean>>, customerIds: Array<number>) {    
    if(this._loadingHelper.isLoading){
      formControls.forEach(formControl => {
        this.setValueWithoutEmitting(formControl, true);
      });
      return;
    }

    this._loadingHelper.startLoading();

    var request = new UpdateFleetWebUserOrganizationsRequest();
    request.userId = this.user.userId;
    request.tenantId = this.user.organization.tenantId;
    request.organizationCustomerIds = customerIds;
    
    this._userService.removeOrganizations(request).subscribe({
      next: () => {
        this._selectedOrganizations = this._selectedOrganizations.filter(selected => {
          return customerIds.findIndex(customerId => customerId == selected.customerId) === -1;
        });  
        if(request.userId === this._authenticationService.currentUserValue.sub){
          this._authenticationService.refreshCurrentUser();
        }              
      },
      error: (error) => {
        this.refreshForm();
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Ta bort ramavtalsbehörighet till kund", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private setValueWithoutEmitting(control: FormControl<boolean>, value: boolean){
    control.setValue(value, {emitEvent: false});   
  }
}
