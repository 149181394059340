import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { QueryResult } from '@domain/models/query-result.model';
import { SearchFleetWebUserQuery } from '@domain/models/filters/search-fleetweb-user-query.model';
import { FleetWebUserInfo } from '@domain/models/user/fleetweb-user-info.model';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { CreateFleetWebUserRequest } from '@domain/models/user/create-fleetweb-user-request.model';
import { UpdateFleetWebUserRequest } from '@domain/models/user/update-fleetweb-user-request.model';
import { AuthwayUserGroup } from '@domain/models/user/authway-user-group.model';
import { DeleteFleetWebUserRequest } from '@domain/models/user/delete-fleetweb-user-request.model';
import { ForceLogoutRequestModel } from '@domain/models/force-logout-request.model';
import { DisconnectFleetWebUserRequest } from '@domain/models/user/disconnect-fleetweb-user-request.model';
import { ConnectFleetWebUserRequest } from '@domain/models/user/connect-fleetweb-user-request.model';
import { UpdateFleetWebUserOrganizationCostCentersRequest } from '@domain/models/user/update-fleetweb-user-organization-costcenters-request.model';
import { UpdateFleetWebUserOrganizationsRequest } from '@domain/models/user/update-fleetweb-user-organizations-request.model';
import { ConnectFleetWebUserResponse } from '@domain/models/user/connect-fleetweb-user-response.model';
import { ValidationErrorResponse } from '@core/models/validation-error-response.model';
import { BusinessSystemPerson } from '@domain/models/business-system-person.model';
import { FleetWebUserGroupRequest } from '@domain/models/user/fleetweb-user-groups-request.model';
import { FleetWebUserRequest } from '@domain/models/user/fleetweb-user-request.model';
import { ExportFleetWebUsersQuery } from '@domain/models/filters/export-fleetweb-users-query.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private _apiService: BaseApiService) { }

  getAvailableUserGroups(tenantId: string) : Observable<Array<AuthwayUserGroup>> {
    return this._apiService.get(`User/AvailableGroups/${tenantId}`).pipe(
      map(data => {
        return data.map(u => new AuthwayUserGroup(u));
      })
    );
  }

  forceLogout(request: ForceLogoutRequestModel): Observable<any> {    
    return this._apiService.post("Authentication/ForceLogout", request).pipe(
      map(data => {          
        return data;
      })
    );    
  }

  getGroups(frameAgreementId: number): Observable<Array<string>> {
    var agString = frameAgreementId ? "/"+frameAgreementId : "";
    return this._apiService.get(`User/Groups`+ agString).pipe(
      map(data => {
        return data.map(u => u.groupName);
      })
    );
  }

  search(searchQuery: SearchFleetWebUserQuery) : Observable<QueryResult<FleetWebUserInfo>> {
    return this._apiService.get(`User/Search${searchQuery.toQueryString()}`).pipe(
      map(data => {
        return new QueryResult<FleetWebUserInfo>(data, FleetWebUserInfo);
      })
    );
  }
  
  exportSearch(query: ExportFleetWebUsersQuery): Observable<Blob> {
    return this._apiService.download(`User/ExportSearch${query.toQueryString()}`);
  }

  get(userId: string) : Observable<FleetWebUserDetails> {
    return this._apiService.get(`User/${userId}`).pipe(map(data => {
      return new FleetWebUserDetails(data);
    }));
  }

  exists(userId: string) : Observable<boolean> {
    return this._apiService.get(`User/Exists/${userId}`);
  }

  create(request: CreateFleetWebUserRequest) : Observable<string> {
    return this._apiService.post('User', request);
  }

  update(request: UpdateFleetWebUserRequest) : Observable<any> {
    return this._apiService.put('User', request);
  }
  
  addGroup(request: FleetWebUserGroupRequest) : Observable<ValidationErrorResponse> {
    return this._apiService.put('User/AddGroup', request);
  }

  removeGroup(request: FleetWebUserGroupRequest) : Observable<ValidationErrorResponse> {
    return this._apiService.put('User/RemoveGroup', request);
  }

  addOrganizations(request: UpdateFleetWebUserOrganizationsRequest) : Observable<any> {
    return this._apiService.put('User/AddOrganizations', request);
  }

  removeOrganizations(request: UpdateFleetWebUserOrganizationsRequest) : Observable<any> {
    return this._apiService.put('User/RemoveOrganizations', request);
  }

  addOrganizationCostCenters(request: UpdateFleetWebUserOrganizationCostCentersRequest) : Observable<any>{
    return this._apiService.put('User/AddOrganizationCostCenters', request);
  }

  removeOrganizationCostCenters(request: UpdateFleetWebUserOrganizationCostCentersRequest) : Observable<any>{
    return this._apiService.put('User/RemoveOrganizationCostCenters', request);
  }
  
  deleteUser(request: DeleteFleetWebUserRequest) : Observable<any> {
    return this._apiService.delete('User', request);
  }

  disconnectUser(request: DisconnectFleetWebUserRequest) : Observable<any> {
    return this._apiService.put('User/Disconnect', request);
  }

  resendInvitationLink(request: FleetWebUserRequest) {
    return this._apiService.post('User/ResendInvitationLink', request);
  }

  validateConnect(customerId: number) : Observable<BusinessSystemPerson | ValidationErrorResponse> {
    return this._apiService.get(`User/ValidateConnect/${customerId}`).pipe(map(data => {
      if (data instanceof ValidationErrorResponse) {
        return data;
      }
  
      return new BusinessSystemPerson(data);
    }));
  }

  connectUser(request: ConnectFleetWebUserRequest) : Observable<ConnectFleetWebUserResponse | ValidationErrorResponse> {
    return this._apiService.put('User/Connect', request).pipe(map(data => {
      if (data instanceof ValidationErrorResponse) {
        return data;
      }
  
      return new ConnectFleetWebUserResponse(data);
    }));
  }
}
