import { Component } from "@angular/core";
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { QueryResult } from "@domain/models/query-result.model";
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";
import { SearchBusinessSystemPersonQuery } from "@domain/models/user/search-business-system-person-query.model";
import { BusinessSystemPersonService } from "@domain/services/business-system-person.service";
import { BusinessSystemConnectionInfo } from "@domain/models/user/business-system-connection-info.model";

@Component({
  standalone: true,
  selector: "app-business-system-person-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: BusinessSystemPersonSearchComponent
    }
  ]
})
export class BusinessSystemPersonSearchComponent extends TypeAheadSearchComponent<BusinessSystemConnectionInfo> {
  
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 10;

  constructor(private _businessSystemPersonService: BusinessSystemPersonService) {
    super();    
  }

  public override get placeholderText(): string {
    return "Sök person i affärssystemet";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: BusinessSystemConnectionInfo): string {
    if (!match || match == null) {
      return "";
    }
    
    return  `${ match.firstName ? match.firstName.trimEnd() : ""} ${match.lastName ? match.lastName.trimEnd() : ""} ${match.socialSecurityNumber} (${match.email})`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade personer.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på personens e-postadress eller personnummer med minst ${minSearchTermLength} bokstäver.`;
  }

  protected override searchAction(term: string): Observable<QueryResult<BusinessSystemConnectionInfo>> {
    const searchQuery = new SearchBusinessSystemPersonQuery();
    searchQuery.searchText = term.toString();
    searchQuery.pageSize = this._maxNumberOfResults;

    return this._businessSystemPersonService.searchBusinessSystemPerson(searchQuery);
  }
  
  protected override isInstanceOfType(value: BusinessSystemConnectionInfo): boolean {
    return value instanceof BusinessSystemConnectionInfo;
  }

  protected override strikeThroughText(_: BusinessSystemConnectionInfo): boolean {
    return false;
  }  
}
