import { Component } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { QueryResult } from "@domain/models/query-result.model";
import { TypeAheadSearchComponent } from "@shared/components/type-ahead-search/type-ahead-search.component";
import { FleetWebOrganizationService } from "@domain/services/fleetweb-organization.service";
import { AuthwayOrganizationInfo } from "@domain/models/organization/authway-organization-info.model";
import { SearchFleetWebOrganizationQuery } from "@domain/models/filters/search-fleetweb-organization-query.model";

@Component({ 
  standalone: true,
  selector: "app-organization-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: OrganizationSearchComponent
    }
  ]
})
export class OrganizationSearchComponent extends TypeAheadSearchComponent<AuthwayOrganizationInfo> {  
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 15;

  disabled = false;
  touched = false;  

  constructor(private _organizationService: FleetWebOrganizationService, parentFormGroup: FormGroupDirective) {
    super(parentFormGroup);  
  }

  public override get placeholderText(): string {
    return "Sök företag/organisation";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: AuthwayOrganizationInfo): string {
    if (!match || match == null) {
      return "";
    }
    
    return  `${match.name ? match.name.trimEnd() : ""} ${match.organizationNumber ? match.organizationNumber: ""}`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade företag/organisationer.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på företagets/organisationens namn eller organisationsnummer med minst ${minSearchTermLength} bokstäver.`;
  }

  protected override searchAction(term: string): Observable<QueryResult<AuthwayOrganizationInfo>> {
    const searchQuery = new SearchFleetWebOrganizationQuery();
    searchQuery.searchText = term.toString();
    searchQuery.pageSize = this._maxNumberOfResults;

    return this._organizationService.searchOrganizations(searchQuery);
  }
  
  protected override isInstanceOfType(value: AuthwayOrganizationInfo): boolean {
    return value instanceof AuthwayOrganizationInfo;
  }

  protected override strikeThroughText(_: AuthwayOrganizationInfo): boolean {
    return false;
  }
}
