export abstract class ApplicationError {    

    protected static standardErrorMessage = "Tekniskt fel - vänligen kontakta Fleet Support.";

    constructor(message: string, status: number) {
        this._message = message;      
        this._status = status;  
    }

    _message: string;
    _status: number;

    get status(){
        return this._status;
    }

    public static getMessage (error: any)  {       
        if(error instanceof(ApplicationError)) {
            return error.fullMessage();
        }                    

        return ApplicationError.standardErrorMessage;
    }

    public fullMessage (_: boolean = false) : string  {
        return this._message;
    }
}
