import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { UserModel } from '@core/models/user.model';
import { Subscription } from 'rxjs';
import { NotificationService } from '@domain/services/notification.service';
import { Permission } from '@core/models/permissions.enum';
import { PermissionGroups } from '@core/models/permission-groups';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FleetWeb';
  currentUser: UserModel;

  private _componentSubscriptions = new Array<Subscription>();

  constructor(
    private _authenticationService: AuthenticationService,
    private _notificationService: NotificationService) { }

  ngOnInit(): void {
    this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(user => {
      this.currentUser = user;
      if (user && user.hasPermission(Permission.LäsaPåminnelser)) {
        this._notificationService.updateNotifications();
      }
      else {
        this._notificationService.resetNotifications();
      }
    }));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }
  
  get isAuthorizedToAdminister(): boolean {
    return this._authenticationService.hasAnyPermission(PermissionGroups.AdministrationPermissions);
  }
}
