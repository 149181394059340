<form [formGroup]="formGroup">
  <label for="inputControl"><b>{{labelText}}</b></label>
  <div class="d-flex justify-content-between align-items-center">
    <input id="inputControl" class="c-input__input" type="text" maxlength="255" formControlName="input" />    
    <button *ngIf="!isEditing" type="button" class="c-btn c-btn--secondary ms-2 icon-button" (click)="onEdit()" title="Ändra">        
        <i class="c-icon c-icon--small c-icon--[pen]"></i> 
    </button>           
  </div>
  <small *ngIf="isError" class="is-error">{{requiredMessage}}</small>
  <div *ngIf="isEditing" class="d-flex justify-content-end mt-2">
    <button type="button" class="c-btn c-btn--secondary me-2" (click)="onCancel()">
      <span class="c-btn__text">Avbryt</span>
    </button>       
    <button type="button" class="c-btn c-btn--primary" (click)="onSave()">
      <span class="c-btn__text">Spara</span>
    </button>       
  </div>
</form>