import { PermissionGroups } from "./permission-groups";
import { Permission } from "./permissions.enum";
import { Severity, UserValidationProblem } from "./user-validation-problem.model";

export class UserModel {
  
  constructor(jsonObject: any) {
    this.userName = jsonObject.userName;
    this.sub = jsonObject.sub;
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.frameAgreementName = jsonObject.frameAgreementName;
    this.email = jsonObject.email;
    this.permissions = jsonObject.permissions;
    this.validationProblems = jsonObject.validationProblems.map (v => new UserValidationProblem(v));
    this.impersonatorUserName = jsonObject.impersonatorUserName;
  }

  userName: string;
  sub: string;
  firstName: string;
  lastName: string;
  frameAgreementName: string;
  email: string;
  permissions: string[];
  validationProblems: Array<UserValidationProblem>;
  impersonatorUserName: string;

  get isImpersonating(): boolean {
    return this.impersonatorUserName != null;
  }

  get hasInternalUserPermissions() :boolean {
    return this.hasAnyPermission(PermissionGroups.InternalUserPermissions);
  }

  get displayName() : string {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`;
      }
      else if (this.firstName) {
        return this.firstName;
      }
      else if (this.lastName) {
        return this.lastName;
      }
      return "";
  }

  hasPermissionStringInput(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  hasPermission(permission: Permission): boolean {
    return this.permissions.includes(permission);
  }

  hasAnyPermission(permissions: Permission[]): boolean {
    return permissions.some(perm => this.permissions.includes(perm));
  }

  hasAllPermissions(permissions: Array<Permission>): boolean {
    return permissions.every(perm => this.permissions.includes(perm));
  }

  get hasPermissions(): boolean {
    return this.permissions.length > 0;
  }

  get hasReportPermissions(): boolean {
    return this.hasAnyPermission(PermissionGroups.ReportPermissions);
  }

  get hasValidationErrors(): boolean {
    return this.validationProblems.some(problem => problem.severity == Severity.Error);
  }

  get fleetContactAddressMissing(): boolean {
    return this.validationProblems.length === 1 && this.validationProblems.some(problem => problem.code == "FleetContactAddressMissing");
  }
}
