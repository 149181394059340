import { FrameAgreement } from "../frame-agreement.model";
import { AuthwayOrganizationInfo } from "../organization/authway-organization-info.model";

export class FleetWebUserInfo {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.userId = jsonObject.userId;
    this.userName = jsonObject.userName;
    this.lastLoginDate = jsonObject.lastLoginDate != null ? new Date(jsonObject.lastLoginDate) : null;      
    this.customerId = jsonObject.customerId;
    this.partId = jsonObject.partId;
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.socialSecurityNumber = jsonObject.socialSecurityNumber;
    this.organization = jsonObject.organization ? new AuthwayOrganizationInfo(jsonObject.organization) : null;
    this.frameAgreement = jsonObject.frameAgreement ? new FrameAgreement(jsonObject.frameAgreement): null;
    this.quotaAccountId = jsonObject.quotaAccountId;
    this.hasConflictingFrameAgreements = jsonObject.hasConflictingFrameAgreements;
    this.hasMultipleFrameAgreementCommitment = jsonObject.hasMultipleFrameAgreementCommitment;
    this.groups = jsonObject.groups ? jsonObject.groups.map(g => g) : new Array<string>();
  }

  userId: string;
  userName: string;
  lastLoginDate: Date;
  customerId?: number;
  partId?: number;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  organization: AuthwayOrganizationInfo;
  frameAgreement: FrameAgreement;
  quotaAccountId: number;
  hasConflictingFrameAgreements: boolean;
  hasMultipleFrameAgreementCommitment: boolean;    
  groups: Array<string>;    
}