import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, ActivatedRoute } from '@angular/router';
import { GetNewsArticleResponse } from '@domain/models/news';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
  @Input() singleNews: GetNewsArticleResponse;
  @Input() smallTitle: boolean;

  loading: boolean;
  navigationExtras: NavigationExtras;
  navigationUrl: string;
  isNewsPage: boolean;
  showMoreText = false;

  constructor(private _route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getUrl();
  }

  toggleShowMore() {
    this.showMoreText = !this.showMoreText;
  }

  private getUrl() {
    const navigationExtras = {
      state: {
        returnRouteBreadcrumb: this._route.snapshot.data["breadcrumb"]
      }
    };

    if (navigationExtras.state.returnRouteBreadcrumb === "Nyheter") {
      this.isNewsPage = true;
    } else {
      this.isNewsPage = false;
    }
  }

  get truncateNews(): boolean {
    return this.singleNews.content.length > 300 && !this.isNewsPage;
  }
}
