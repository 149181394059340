<div *ngIf="isAuthorizedToAdminister" class="super-user-menu-container">
    <app-super-user-menu></app-super-user-menu>
</div>

<div [class.super-user-main-container]="isAuthorizedToAdminister">
    <app-header *ngIf="currentUser"></app-header>
    <ng-container *ngIf="currentUser">
        <app-hero></app-hero>
    </ng-container>

    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
