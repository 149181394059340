export class FleetWebOrganizationInfo {
  constructor(jsonObject: any = null) {
    
    if(jsonObject == null){
      return;
    }

    this.name = jsonObject.name;
    this.organizationNumber = jsonObject.organizationNumber;
    this.frameAgreementId = jsonObject.frameAgreementId;
    this.customerId = jsonObject.customerId;

    this.tenantId = jsonObject.tenantId;
    this.connectedFrameAgreementId = jsonObject.connectedFrameAgreementId;
    this.isPrincipal = jsonObject.isPrincipal;
    this.authwayName = jsonObject.authwayName;
  }

  name: string;
  organizationNumber: string;
  frameAgreementId: number = null;
  customerId: number = null;
  
  connectedFrameAgreementId: number = null;
  tenantId: string = null;
  isPrincipal: boolean = false;
  authwayName: string = null;
  
  get hasWarnings() {    
    return !this.existsInView21 || this.isConnectedToOtherFrameAgreementInAuthway;
  }

  get hasErrors() {
    return (this.existsInView21 && !this.isConnected && !this.usingPrincipalOrganization) || this.authwayOrganizationRequired;
  }

  get existsInView21(): boolean{
    return this.customerId !== null;
  }

  get isConnected (): boolean{
    return this.connectedFrameAgreementId !== null;
  }

  get hasAuthwayOrganization(): boolean {
    return this.tenantId !== null;
  }
 
  get hasSynchronizedFrameAgreements(): boolean{
    return this.frameAgreementId != null && this.connectedFrameAgreementId != null && this.frameAgreementId === this.connectedFrameAgreementId;
  }
  
  get isConnectedToOtherFrameAgreementInAuthway() : boolean{
    return this.frameAgreementId != null && this.connectedFrameAgreementId != null && this.frameAgreementId !== this.connectedFrameAgreementId;
  }

  get authwayOrganizationRequired(){
    return this.isPrincipal && this.tenantId == null
  }

  get usingPrincipalOrganization(){
    return !this.isPrincipal && this.tenantId == null
  }
}